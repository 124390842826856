import { useContext, useEffect, useRef, useState } from "react";
import { groupBy } from "ramda";
import { keyBy } from "lodash";

import Typography from "@mui/material/Typography";
import RemoveIcon from "@mui/icons-material/HighlightOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import {
  Box,
  InputAdornment,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SevereColdIcon from "@mui/icons-material/SevereCold";

import { getCrewOverview, getAircraftSpecialization } from "../../api/crew";

import PositionOverviewTable from "../../components/PositionOverviewTable";
import SearchField from "../../components/TableComponent/SearchField";
import { formatLastFirstName } from "../../services/formatters";
import Button from "../../components/Button";
import { Autocomplete } from "src/components/Form/Autocomplete";
import { createSelectableObject } from "src/components/Form/types";

import "./index.scss";
import DisplayContext from "../../context/DisplayContext";
import { getUserCertificates, getUserProperties } from "../../api/user";
import { getAllCourses } from "../../api/courses";
import OperationsService from "../operations/service";
import html2canvas from "html2canvas";

function takeScreenshot(fullscreen) {
  const param = {
    allowTaint: false,
    useCORS: true,
    scale: 5,
  };
  let myDiv = "myDiv";
  if (fullscreen) {
    myDiv = "main";
  }
  const input = document.getElementById(myDiv);
  html2canvas(input, param).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = "screenshot.png";
    link.href = imgData;
    document.body.appendChild(link);
    link.click();
  });
}

const collectMyCourses = (operations, user, courses, properties, certificates) => {
  const operationsList = OperationsService.getOperations(
    properties,
    courses.courses,
    courses.customCourses,
    courses.groups,
    courses.relations,
  );
  const opInfos = keyBy(OperationsService.toDisplayObject(operationsList, certificates, courses.relations), "id");

  const res = [user.name];
  for (const op of operations) {
    const opInfo = opInfos[`${op.category_id}-${op.position_id}-${op.operation_id}`];
    if (opInfo) {
      res.push(opInfo.status);
    } else {
      res.push("");
    }
  }
  return res;
};

const hasOperations = (user) => !!user.assignedRoles.find((u) => !!u.operation_id);

// position_name consists of a function (e.g. pilot)
// and something like "high-level operation" / "operations group" (e.g. CAT)
const functions = [
  ["pilot"],
  ["task specialist operator", "TSOP"],
  ["flight dispatch"],
  ["flight instructor", "FI"],
  ["flight examiner", "FE"],
  ["type rating examiner", "TRE"],
  ["type rating instructor", "TRI"],
  ["ground instructor", "GI"],
].map(([f, short]) => [new RegExp(`\\b(?:${f}${short ? "|" + short : ""})\\b`, "i"), f, short]);

const getPosFuncPrefix = (pos) => {
  for (const [re, f, short] of functions) {
    if (re.test(pos)) {
      // prefix with 0 so these get shown first
      // then add short if exists, or long form, for proper sorting by function
      // then add the full position name for further SORTING beyond / ignoring function
      return `0${short || f}${pos}`;
    }
  }
  return pos;
};

export const LimitationsFilter = ({ defaultFilterMode = "filter", mode = "normal" }) => {
  // These states would be used in a real implementation
  const [expanded, setExpanded] = useState(false);
  const [wind, setWind] = useState(mode === "normal" ? "" : []);
  const [gusts, setGusts] = useState(mode === "normal" ? "" : []);
  const [visibility, setVisibility] = useState(mode === "normal" ? "" : []);
  const [ceiling, setCeiling] = useState(mode === "normal" ? "" : []);
  const [pax, setPax] = useState([]);
  const [performance, setPerformance] = useState([]);
  const [nightOperations, setNightOperations] = useState(false);
  const [filterMode, setFilterMode] = useState(defaultFilterMode);

  const limitations = {
    Wind: {
      operator: "<",
      values: ["20", "25", "30"],
    },
    Gusts: { operator: "<", values: ["10", "15", "20"] },
    Visibility: { operator: ">", values: ["3", "5", "8", "10"] },
    Ceiling: { operator: ">", values: ["800", "1000", "1500", "2000"] },
  };

  const conditionsEnabled = () => {
    return (
      (mode === "normal" ? wind : wind.length) ||
      (mode === "normal" ? gusts : gusts.length) ||
      (mode === "normal" ? visibility : visibility.length) ||
      (mode === "normal" ? ceiling : ceiling.length) ||
      pax.length ||
      performance.length ||
      nightOperations
    );
  };

  const clearConditions = () => {
    setWind(mode === "normal" ? "" : []);
    setGusts(mode === "normal" ? "" : []);
    setVisibility(mode === "normal" ? "" : []);
    setCeiling(mode === "normal" ? "" : []);
    setPax([]);
    setPerformance([]);
    setNightOperations(false);
  };

  const getHelpText = (limitation, value) => {
    if (!value) return "";

    const reverse = filterMode === "show";
    const { operator, values } = limitations[limitation];
    const numValue = parseFloat(value);

    // Determine effective operator based on reverse flag
    const effectiveOperator = reverse ? (operator === "<" ? ">" : "<") : operator;

    const matchingValues = values.filter((val) => {
      const numVal = parseFloat(val);
      // Use effective operator for comparison logic
      return effectiveOperator === "<" ? numValue >= numVal : numValue <= numVal;
    });

    if (!matchingValues.length) return "";

    // Use effective operator in the result string
    return `${reverse ? "Showing" : "Filtering"}: ${matchingValues.length === values.length ? "All" : matchingValues.join(", ")}`;
  };

  return (
    <Accordion
      sx={{
        marginBottom: "20px",
        width: "100%",
      }}
      expanded={expanded}
      onChange={(event, isExpanded) => setExpanded(isExpanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="limitations-filter-content"
        id="limitations-filter-header"
        sx={{
          backgroundColor: "#f5f5f5",
          "&.Mui-expanded": {
            minHeight: "48px",
          },
          "& .MuiAccordionSummary-content": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 0,
            marginBottom: 0,
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
          <SevereColdIcon sx={{ mr: 1, color: conditionsEnabled() ? "#a6e22e" : "inherit" }} />
          Current Conditions
          {conditionsEnabled() && (
            <Button
              icon={<RemoveIcon />}
              onClick={(e) => {
                e.stopPropagation();
                clearConditions();
              }}
              title="Clear all conditions"
              // sx={{ ml: 2 }}
            />
          )}
        </Typography>

        {expanded && (
          <RadioGroup
            row
            name="filter-mode"
            value={filterMode}
            onChange={(e) => setFilterMode(e.target.value)}
            sx={{ ml: 2 }}
            onClick={(e) => e.stopPropagation()} // Prevent accordion from toggling when clicking radio buttons
          >
            <FormControlLabel value="filter" control={<Radio size="small" />} label="Filter out" sx={{ mr: 0.5 }} />
            <FormControlLabel
              value="show"
              control={<Radio size="small" />}
              label="Show only"
              sx={{ marginLeft: "1rem" }}
            />
          </RadioGroup>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "16px 24px 24px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            {mode === "normal" ? (
              <TextField
                label="Wind"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">kt</InputAdornment>,
                }}
                fullWidth
                helperText={getHelpText("Wind", wind)}
                value={wind}
                onChange={(e) => setWind(e.target.value)}
              />
            ) : (
              <FormControl fullWidth sx={{ width: "100%" }}>
                <InputLabel id="wind-select-label">Wind</InputLabel>
                <Select
                  labelId="wind-select-label"
                  id="wind-select"
                  multiple
                  value={wind}
                  label="Wind"
                  onChange={(e) => setWind(e.target.value)}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          size="small"
                          onDelete={(e) => {
                            e.stopPropagation();
                            setWind(wind.filter((item) => item !== value));
                          }}
                          onMouseDown={(e) => e.stopPropagation()}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                      },
                    },
                  }}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="< 20">{"<"} 20</MenuItem>
                  <MenuItem value="< 25">{"<"} 25</MenuItem>
                  <MenuItem value="< 30">{"<"} 30</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {mode === "normal" ? (
              <TextField
                label="Gusts"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">kt</InputAdornment>,
                }}
                fullWidth
                helperText={getHelpText("Gusts", gusts)}
                value={gusts}
                onChange={(e) => setGusts(e.target.value)}
              />
            ) : (
              <FormControl fullWidth sx={{ width: "100%" }}>
                <InputLabel id="gusts-select-label">Gusts</InputLabel>
                <Select
                  labelId="gusts-select-label"
                  id="gusts-select"
                  multiple
                  value={gusts}
                  label="Gusts"
                  onChange={(e) => setGusts(e.target.value)}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          size="small"
                          onDelete={(e) => {
                            e.stopPropagation();
                            setGusts(gusts.filter((item) => item !== value));
                          }}
                          onMouseDown={(e) => e.stopPropagation()}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                      },
                    },
                  }}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="< 10">{"<"} 10</MenuItem>
                  <MenuItem value="< 15">{"<"} 15</MenuItem>
                  <MenuItem value="< 20">{"<"} 20</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {mode === "normal" ? (
              <TextField
                label="Visibility"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">km</InputAdornment>,
                }}
                fullWidth
                helperText={getHelpText("Visibility", visibility)}
                value={visibility}
                onChange={(e) => setVisibility(e.target.value)}
              />
            ) : (
              <FormControl fullWidth sx={{ width: "100%" }}>
                <InputLabel id="visibility-select-label">Visibility</InputLabel>
                <Select
                  labelId="visibility-select-label"
                  id="visibility-select"
                  multiple
                  value={visibility}
                  label="Visibility"
                  onChange={(e) => setVisibility(e.target.value)}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          size="small"
                          onDelete={(e) => {
                            e.stopPropagation();
                            setVisibility(visibility.filter((item) => item !== value));
                          }}
                          onMouseDown={(e) => e.stopPropagation()}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                      },
                    },
                  }}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="> 3">{">"} 3</MenuItem>
                  <MenuItem value="> 5">{">"} 5</MenuItem>
                  <MenuItem value="> 8">{">"} 8</MenuItem>
                  <MenuItem value="> 10">{">"} 10</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {mode === "normal" ? (
              <TextField
                label="Ceiling"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">ft</InputAdornment>,
                }}
                fullWidth
                helperText={getHelpText("Ceiling", ceiling)}
                value={ceiling}
                onChange={(e) => setCeiling(e.target.value)}
              />
            ) : (
              <FormControl fullWidth sx={{ width: "100%" }}>
                <InputLabel id="ceiling-select-label">Ceiling</InputLabel>
                <Select
                  labelId="ceiling-select-label"
                  id="ceiling-select"
                  multiple
                  value={ceiling}
                  label="Ceiling"
                  onChange={(e) => setCeiling(e.target.value)}
                  w
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          size="small"
                          onDelete={(e) => {
                            e.stopPropagation();
                            setCeiling(ceiling.filter((item) => item !== value));
                          }}
                          onMouseDown={(e) => e.stopPropagation()}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                      },
                    },
                  }}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="> 800">{">"} 800</MenuItem>
                  <MenuItem value="> 1000">{">"} 1000</MenuItem>
                  <MenuItem value="> 1500">{">"} 1500</MenuItem>
                  <MenuItem value="> 2000">{">"} 2000</MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="pax-select-label">PAX</InputLabel>
              <Select
                labelId="pax-select-label"
                id="pax-select"
                multiple
                value={pax}
                label="PAX"
                onChange={(e) => setPax(e.target.value)}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        size="small"
                        onDelete={(e) => {
                          e.stopPropagation();
                          setPax(pax.filter((item) => item !== value));
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                    },
                  },
                }}
              >
                <MenuItem value="None">None</MenuItem>
                <MenuItem value="VFR only">VFR only</MenuItem>
                <MenuItem value="Max">Max</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="performance-select-label">Performance</InputLabel>
              <Select
                labelId="performance-select-label"
                id="performance-select"
                multiple
                value={performance}
                label="Performance"
                onChange={(e) => setPerformance(e.target.value)}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        size="small"
                        onDelete={(e) => {
                          e.stopPropagation();
                          setPerformance(performance.filter((item) => item !== value));
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                    },
                  },
                }}
              >
                <MenuItem value="HOGE">HOGE</MenuItem>
                <MenuItem value="HIGE">HIGE</MenuItem>
                <MenuItem value="Limited">Limited</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nightOperations}
                  onChange={(e) => setNightOperations(e.target.checked)}
                  name="nightOperations"
                />
              }
              label="Night Operations"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const PositionOverview = (params) => {
  const crewId = params.match.params.crewId;
  const [positions, setPositions] = useState([]);
  const [aircraft, setAircraft] = useState([]);
  const [selectedAircraft, setSelectedAircraft] = useState("");
  const [users, setUsers] = useState([]);
  const [noData, setNoData] = useState(false);
  const [search, setSearch] = useState("");
  const [searchOp, setSearchOp] = useState("");

  const searchRef = useRef();
  const { fullscreen, toggleFullscreen } = useContext(DisplayContext);

  function copyArraySkipFirst(arr, times) {
    const result = [arr[0]];

    for (let i = 1; i < arr.length; i++) {
      for (let j = 0; j < times; j++) {
        result.push(arr[i]);
      }
    }

    return result;
  }

  useEffect(() => {
    if (!crewId) return;
    (async () => {
      const [overview, courses, properties, certificates, aircraft] = await Promise.all([
        getCrewOverview(crewId),
        getAllCourses(crewId),
        getUserProperties(crewId),
        getUserCertificates(null, crewId),
        getAircraftSpecialization(crewId),
      ]);

      if (overview) {
        const positions = Object.values(
          groupBy(
            (x) => x.position_id,
            overview.positions
              .filter((p) => !!p.operation_id)
              .sort((a, b) => a?.operation_name.localeCompare(b?.operation_name)),
          ),
        ).sort(([a], [b]) => getPosFuncPrefix(a?.position_name).localeCompare(getPosFuncPrefix(b?.position_name)));
        const operations = positions.reduce((acc, pos) => [...acc, ...pos], []);
        const users = overview.users
          .map((user) => {
            if (!hasOperations(user)) {
              return null;
            }
            const collected = collectMyCourses(
              operations,
              user,
              courses,
              properties[user.user_id],
              certificates[user.user_id],
            );
            return {
              positions: collected,
              id: user.user_id,
            };
          })
          .filter((x) => !!x)
          .sort(({ positions: [name1] }, { positions: [name2] }) =>
            formatLastFirstName(name1).localeCompare(formatLastFirstName(name2)),
          );

        const companyUsedAircraftIds = [...new Set(overview.users.flatMap((user) => user.aircraft))];
        const companyUsedAircraft = aircraft.filter((aircraft) => companyUsedAircraftIds.includes(aircraft.id));

        users.forEach((user) => {
          user.positions = copyArraySkipFirst(user.positions, selectedAircraft ? 1 : companyUsedAircraftIds.length);
        });

        setPositions(positions);
        setAircraft(companyUsedAircraft);
        setUsers(users);
        setNoData(!positions.length);
      }
    })();
  }, [crewId, selectedAircraft]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          backgroundColor: "white",
          paddingBottom: "10px",
          marginBottom: "10px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <LimitationsFilter />

        <Grid container spacing={2} sx={{ mt: 1, justifyContent: "space-between", marginBottom: "8px" }}>
          <Grid item xs={12} sm={9} md={8} lg={9} sx={{ paddingTop: "0px !important" }}>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: 1 }}>
              <SearchField
                placeholder="Filter users"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  // sx: { minHeight: "40px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button icon={<RemoveIcon />} onClick={() => setSearch("")} title={"Clear filter"} />
                    </InputAdornment>
                  ),
                }}
                sx={{ minWidth: "180px" }}
              />
              <SearchField
                placeholder="Filter operations"
                value={searchOp}
                onChange={(e) => setSearchOp(e.target.value)}
                InputProps={{
                  // sx: { minHeight: "40px" },
                  ref: searchRef,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button icon={<RemoveIcon />} onClick={() => setSearchOp("")} title={"Clear filter"} />
                    </InputAdornment>
                  ),
                }}
                sx={{ minWidth: "180px" }}
              />
              <FormControl sx={{ minWidth: "180px" }}>
                <Autocomplete
                  items={aircraft.map((aircraft) =>
                    createSelectableObject(aircraft.id, aircraft.name || aircraft.type),
                  )}
                  selected={selectedAircraft}
                  label={"Filter aircraft"}
                  onChange={(selected) => {
                    setSelectedAircraft(selected);
                  }}
                />
              </FormControl>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            md={4}
            lg={3}
            sx={{ display: "flex", justifyContent: "flex-end", paddingTop: "0px !important" }}
          >
            <Paper elevation={2} sx={{ p: 1, display: "flex", gap: 1, height: "40px" }}>
              <Button
                icon={fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                onClick={() => toggleFullscreen()}
                iconSize={"large"}
                title="Toggle fullscreen view"
              />
              <Button
                icon={fullscreen ? <AddAPhotoIcon /> : <CameraAltIcon />}
                onClick={() => takeScreenshot(fullscreen)}
                iconSize={"large"}
                title="Take screenshot"
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ overflow: "auto", flexGrow: 1 }}>
        <div id="myDiv" style={{ paddingTop: "10px" }}>
          {noData ? <Typography variant="h4">No Data Found</Typography> : null}
          {positions?.length && users?.length ? (
            <PositionOverviewTable
              crewId={crewId}
              positions={positions}
              aircraft={selectedAircraft ? aircraft.filter((a) => a.id === selectedAircraft.getId()) : aircraft}
              users={users.filter(
                ({ positions: [name] }) => !search || name.toLowerCase().includes(search.toLowerCase()),
              )}
              onUserSelected={(name) => (search === name ? setSearch("") : setSearch(name))}
              onOperationSelected={(it) => setSearchOp(searchOp === it ? "" : it)}
              onPositionSelected={(it) => setSearchOp(searchOp === it ? "" : it)}
              searchOp={searchOp}
              searchRef={searchRef}
            />
          ) : null}
        </div>
      </Box>
    </Box>
  );
};

export default PositionOverview;
