import { useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MenuItem from "@mui/material/MenuItem";
import FlightIcon from "@mui/icons-material/Flight";
import MoveDown from "@mui/icons-material/MoveDown";

// audit trail
import HistoryIcon from "@mui/icons-material/History";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";

// advanced limitations
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import FormLabel from "@mui/material/FormLabel";
import EditIcon from "@mui/icons-material/Edit";
import Select from "@mui/material/Select";

import TableComponent from "../../components/TableComponent";
import Button from "../../components/Button";
import { StyledSwitch } from "../../components/Base";
import ExpandableSection from "../../components/ExpandableSection";

import { Row } from "../../components/Form";
import { StyledPaper } from "../../components/Base";

import { getAircraftSpecialization, getCrewRelations } from "../../api/crew";
import {
  addAircraftSpecialization,
  deleteAircraftSpecialization,
  getUserAircraft,
  getUserProperties,
} from "../../api/user";
import PropertiesService from "../properties-edit/service";
import useUser from "../../hooks/useUser";

import IdGenerator from "../../utils/IdGenerator";

const idGenerator = new IdGenerator();

// Demo data for limitation sets and individual limitations
const limitationSets = [
  { id: "cat1", name: "Set 1", description: "Light limitations (Visibility > 5km, Wind < 15kts)" },
  { id: "cat2", name: "Set 2", description: "Moderate limitations (Visibility > 3km, Wind < 25kts)" },
  { id: "cat3", name: "Set 3", description: "Severe limitations (Visibility > 1.5km, Wind < 35kts)" },
];

const individualLimitations = [
  { id: "wind_15", name: "Wind < 15kts", group: "Wind" },
  { id: "wind_25", name: "Wind < 25kts", group: "Wind" },
  { id: "wind_35", name: "Wind < 35kts", group: "Wind" },
  { id: "vis_3", name: "Visibility > 3km", group: "Visibility" },
  { id: "vis_5", name: "Visibility > 5km", group: "Visibility" },
  { id: "vis_8", name: "Visibility > 8km", group: "Visibility" },
  { id: "ceil_500", name: "Ceiling > 500ft", group: "Ceiling" },
  { id: "ceil_1000", name: "Ceiling > 1000ft", group: "Ceiling" },
  { id: "ceil_1500", name: "Ceiling > 1500ft", group: "Ceiling" },
  { id: "day_only", name: "Day only", group: "Time" },
];

// Group limitations by their group
const groupedLimitations = individualLimitations.reduce((acc, limitation) => {
  if (!acc[limitation.group]) {
    acc[limitation.group] = [];
  }
  acc[limitation.group].push(limitation);
  return acc;
}, {});

const AircraftSpecialization = ({ match, history }) => {
  const [aircraftList, setAircraftList] = useState([]);
  const [specialization, setSpecialization] = useState({});
  const [selectedAircraft, setSelectedAircraft] = useState({});
  const [assignedList, setAssignedList] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [userProps, setUserProps] = useState([]);
  const [companyUsesSets, setCompanyUsesSets] = useState(false);

  const { crewId, userId } = match.params;
  useEffect(() => {
    (async () => {
      const newAircraft = await getAircraftSpecialization(crewId);
      const userAircraft = await getUserAircraft(crewId, userId);
      const crewRelations = await getCrewRelations(crewId);
      const props = await getUserProperties(crewId, userId);

      const userProps = PropertiesService.toDisplayObject(props, crewRelations);
      setUserProps(userProps);

      setAircraftList(newAircraft);
      const newAssignedList = [];
      for (const aircraft of userAircraft) {
        const crewAircraft = newAircraft.find((item) => item.id === aircraft.id);
        // skip if not found
        if (!crewAircraft) continue;
        newAssignedList.push({ ...aircraft, name: crewAircraft.name });
      }

      // expand specializations array and add keyId
      const expandedAssignedList = [];
      for (const aircraft of newAssignedList) {
        if (!aircraft.specializations) {
          expandedAssignedList.push({
            id: aircraft.id,
            name: aircraft.name,
            keyId: idGenerator.getGeneratorId(),
          });
        } else {
          aircraft.specializations.forEach((specializationId) => {
            const specializationName = newAircraft
              .find((aircraftListItem) => aircraftListItem.id === aircraft.id)
              ?.specializations.find((specialization) => specialization.id === specializationId)?.name;

            const expandedAircraft = {
              keyId: idGenerator.getGeneratorId(),
              specialization: {
                id: specializationId,
                name: specializationName,
              },
              name: aircraft.name,
              id: aircraft.id,
            };
            expandedAssignedList.push(expandedAircraft);
          });
        }
      }
      setAssignedList(expandedAssignedList);
    })();
  }, [crewId, userId, refresh]);

  function setAircraft(value) {
    setSelectedAircraft(value);
    setSpecialization({});
  }

  async function addNewAircraftSpecialization() {
    await addAircraftSpecialization({
      crewId,
      userId,
      typeId: selectedAircraft.id,
      specializationId: specialization?.id ? specialization.id : null,
    });
    setSpecialization({});
    setSelectedAircraft({});

    setRefresh(refresh * -1);
  }

  async function removeAircraftSpecialization(id) {
    const assignedAircraft = assignedList.find((item) => item.id === id);
    await deleteAircraftSpecialization({
      crewId,
      userId,
      typeId: assignedAircraft.id,
      specializationId: assignedAircraft?.specialization?.id ? assignedAircraft?.specialization?.id : null,
    });
    setRefresh(refresh * -1);
  }

  const limitations = ["No limitation", "20 kts | 5 km", "40 kts | 3 km", "60 kts | 2 km"];
  const [aircraftOperations, setAircraftOperations] = useState({});
  const [aircraftSearchTerm, setAircraftSearchTerm] = useState("");
  const [operationSearchTerm, setOperationSearchTerm] = useState("");
  const [expandedSections, setExpandedSections] = useState({});
  const [originalExpandedState, setOriginalExpandedState] = useState(null);

  const [operationHistory, setOperationHistory] = useState({});
  const [showHistory, setShowHistory] = useState(null);

  const filterItems = (items, term) => {
    if (!term) return items;
    const lowerTerm = term.toLowerCase();
    return items.filter((item) => item.label.toLowerCase().includes(lowerTerm));
  };

  const filteredOperations = filterItems(userProps, operationSearchTerm);

  const filteredAssignedList = aircraftSearchTerm
    ? assignedList.filter((aircraft) => aircraft.name.toLowerCase().includes(aircraftSearchTerm.toLowerCase()))
    : assignedList;

  const handleOperationSearch = (e) => {
    const newSearchTerm = e.target.value;
    setOperationSearchTerm(newSearchTerm);

    if (newSearchTerm) {
      if (originalExpandedState === null) {
        setOriginalExpandedState({ ...expandedSections });
      }
      const allExpanded = {};
      assignedList.forEach((aircraft) => {
        allExpanded[aircraft.id] = true;
      });
      setExpandedSections(allExpanded);
    } else {
      if (originalExpandedState !== null) {
        setExpandedSections({ ...originalExpandedState });
        setOriginalExpandedState(null);
      }
    }
  };

  const handleSectionToggle = (aircraftId, isExpanded) => {
    if (!operationSearchTerm) {
      setExpandedSections((prev) => ({
        ...prev,
        [aircraftId]: isExpanded,
      }));
    }
  };

  const AircraftTitle = ({ title, aircraftId }) => (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FlightIcon sx={{ mr: 1 }} />
        <Typography variant="body1" style={{ marginLeft: ".5rem" }}>
          {title}
        </Typography>
      </Box>
      <Button
        title="Delete Aircraft and related specializations"
        icon={<DeleteForeverIcon color="error" />}
        onClick={(e) => {
          e.stopPropagation();
          removeAircraftSpecialization(aircraftId);
        }}
      />
    </Box>
  );

  const Operation = ({ name, aircraftId, isFirst = false }) => {
    const style = { border: 0 };
    const styleSwitch = { border: 0, textAlign: "center" };

    const user = useUser();
    const [showCopyConfirm, setShowCopyConfirm] = useState(false);
    const [showLimitationsDialog, setShowLimitationsDialog] = useState(false);

    const operationState = aircraftOperations[aircraftId]?.[name.label] || {
      allowed: false,
      underSupervision: false,
      limitations: limitations[0],
      limitationType: "none",
      limitationSet: "unlimited",
      customLimitations: {},
    };

    const operationKey = `${aircraftId}_${name.label}`;
    const history = operationHistory[operationKey] || [];

    const addHistoryEntry = (change) => {
      setOperationHistory((prev) => ({
        ...prev,
        [operationKey]: [
          ...(prev[operationKey] || []),
          {
            timestamp: new Date(),
            user: user.fullname,
            change,
          },
        ],
      }));
    };

    const handleOperationChange = (field) => (event) => {
      const value = field === "limitations" ? event.target.value : event.target.checked;

      // Record history
      const changeMessage =
        field === "limitations"
          ? `Changed limitation to: ${value}`
          : field === "allowed"
            ? `${value ? "Allowed" : "Denied"} operation`
            : `Set under supervision: ${value}`;

      addHistoryEntry(changeMessage);

      setAircraftOperations((prev) => ({
        ...prev,
        [aircraftId]: {
          ...prev[aircraftId],
          [name.label]: {
            ...operationState,
            [field]: value,
          },
        },
      }));
    };

    const handleOpenLimitationsDialog = () => {
      setShowLimitationsDialog(true);
    };

    const renderLimitationsInfo = () => {
      const content = getLimitationsDisplayContent();

      return (
        <Box
          onClick={operationState.allowed ? handleOpenLimitationsDialog : undefined}
          sx={{
            p: 1,
            cursor: operationState.allowed ? "pointer" : "default",
            borderBottom: "1px dotted #00000055",
            "&:hover": operationState.allowed
              ? {
                  backgroundColor: "#f5f5f5",
                  borderColor: "#0000008A",
                }
              : {},
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "36px",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{content}</Box>
          {operationState.allowed && <EditIcon fontSize="small" color="action" sx={{ opacity: 0.6, ml: 1 }} />}
        </Box>
      );
    };

    const getLimitationsDisplayContent = () => {
      if (!operationState.allowed) {
        return (
          <Typography variant="body2" color="text.disabled">
            Not applicable
          </Typography>
        );
      }

      if (operationState.limitationType === "none") {
        return <Typography variant="body2">No limitations</Typography>;
      }

      if (companyUsesSets && operationState.limitationType === "set") {
        const set = limitationSets.find((cat) => cat.id === operationState.limitationSet);
        return set ? (
          <Typography variant="body2">{set.name}</Typography>
        ) : (
          <Typography variant="body2">No limitations</Typography>
        );
      } else {
        const selectedLimitationIds = Object.values(operationState.customLimitations || {}).filter((id) => id);

        if (selectedLimitationIds.length === 0) {
          return <Typography variant="body2">No limitations</Typography>;
        }

        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selectedLimitationIds.slice(0, 2).map((limId) => {
              const limitation = individualLimitations.find((lim) => lim.id === limId);
              return limitation ? (
                <Chip key={limId} label={limitation.name} size="small" sx={{ height: "20px", fontSize: "0.7rem" }} />
              ) : null;
            })}
            {selectedLimitationIds.length > 2 && (
              <Chip
                label={`+${selectedLimitationIds.length - 2} more`}
                size="small"
                sx={{ height: "20px", fontSize: "0.7rem" }}
              />
            )}
          </Box>
        );
      }
    };

    const handleSaveLimitations = (newLimitationsData) => {
      const changeMessages = [];

      if (operationState.limitationType !== newLimitationsData.limitationType) {
        if (newLimitationsData.limitationType === "none") {
          changeMessages.push("Removed all limitations");
        } else if (newLimitationsData.limitationType === "set") {
          const set = limitationSets.find((cat) => cat.id === newLimitationsData.limitationSet);
          changeMessages.push(`Changed to set: ${set?.name || newLimitationsData.limitationSet}`);
        } else if (newLimitationsData.limitationType === "custom") {
          // For custom, include the selected limitations in the message
          const selectedLimitations = Object.entries(newLimitationsData.customLimitations || {})
            .filter(([_, value]) => value)
            .map(([group, limId]) => {
              const limitation = individualLimitations.find((lim) => lim.id === limId);
              return limitation ? `${group}: ${limitation.name}` : null;
            })
            .filter(Boolean);

          if (selectedLimitations.length > 0) {
            changeMessages.push(`Changed to custom limitations: ${selectedLimitations.join(", ")}`);
          } else {
            changeMessages.push("Changed to custom limitations with no values selected");
          }
        }
      } else if (
        newLimitationsData.limitationType === "set" &&
        operationState.limitationSet !== newLimitationsData.limitationSet
      ) {
        // Set changed
        const oldSet = limitationSets.find((cat) => cat.id === operationState.limitationSet);
        const newSet = limitationSets.find((cat) => cat.id === newLimitationsData.limitationSet);
        changeMessages.push(`Changed set from "${oldSet?.name || "none"}" to "${newSet?.name || "none"}"`);
      } else if (newLimitationsData.limitationType === "custom") {
        // Custom limitations changed - track specific changes
        Object.keys(groupedLimitations).forEach((group) => {
          const oldLimId = operationState.customLimitations?.[group];
          const newLimId = newLimitationsData.customLimitations?.[group];

          if (oldLimId !== newLimId) {
            const oldLimitation = oldLimId ? individualLimitations.find((lim) => lim.id === oldLimId) : null;
            const newLimitation = newLimId ? individualLimitations.find((lim) => lim.id === newLimId) : null;

            const oldValue = oldLimitation ? oldLimitation.name : "No limitation";
            const newValue = newLimitation ? newLimitation.name : "No limitation";

            changeMessages.push(`Changed ${group} limitation from "${oldValue}" to "${newValue}"`);
          }
        });
      }

      changeMessages.forEach((change) => {
        addHistoryEntry(change);
      });

      setAircraftOperations((prev) => ({
        ...prev,
        [aircraftId]: {
          ...prev[aircraftId],
          [name.label]: {
            ...operationState,
            ...newLimitationsData,
          },
        },
      }));

      setShowLimitationsDialog(false);
    };

    const copyToAllOperations = () => {
      const operationsToUpdate = filteredOperations.map((op) => op.label);

      // Add history entries for operations that will change
      operationsToUpdate.forEach((opLabel) => {
        if (opLabel === name.label) return; // Skip the current operation

        const targetOperationKey = `${aircraftId}_${opLabel}`;
        const currentState = aircraftOperations[aircraftId]?.[opLabel] || {
          allowed: false,
          underSupervision: false,
          limitations: limitations[0],
          useLimitationSet: true,
          limitationSet: "unlimited",
          customLimitations: {},
        };

        // Only log if any state is actually changing
        const hasBasicChanges =
          currentState.allowed !== operationState.allowed ||
          currentState.underSupervision !== operationState.underSupervision;

        const hasLimitationTypeChange = currentState.useLimitationSet !== operationState.useLimitationSet;

        const hasSetChange =
          currentState.useLimitationSet &&
          operationState.useLimitationSet &&
          currentState.limitationSet !== operationState.limitationSet;

        let hasCustomLimitationsChange = false;
        if (!currentState.useLimitationSet && !operationState.useLimitationSet) {
          const currentLimitations = currentState.customLimitations || {};
          const newLimitations = operationState.customLimitations || {};

          hasCustomLimitationsChange = Object.keys(groupedLimitations).some(
            (group) => currentLimitations[group] !== newLimitations[group],
          );
        }

        if (hasBasicChanges) {
          if (currentState.allowed !== operationState.allowed) {
            setOperationHistory((prev) => ({
              ...prev,
              [targetOperationKey]: [
                ...(prev[targetOperationKey] || []),
                {
                  timestamp: new Date(),
                  user: user.fullname,
                  change: `${operationState.allowed ? "Allowed" : "Denied"} operation`,
                },
              ],
            }));
          }

          if (currentState.underSupervision !== operationState.underSupervision) {
            setOperationHistory((prev) => ({
              ...prev,
              [targetOperationKey]: [
                ...(prev[targetOperationKey] || []),
                {
                  timestamp: new Date(),
                  user: user.fullname,
                  change: `Set under supervision: ${operationState.underSupervision}`,
                },
              ],
            }));
          }
        }

        // Record limitation changes
        if (hasLimitationTypeChange && operationState.limitationType === "custom") {
          // Create detailed message about the custom limitations being applied
          const selectedLimitations = Object.entries(operationState.customLimitations || {})
            .filter(([_, value]) => value)
            .map(([group, limId]) => {
              const limitation = individualLimitations.find((lim) => lim.id === limId);
              return limitation ? `${group}: ${limitation.name}` : null;
            })
            .filter(Boolean);

          if (selectedLimitations.length > 0) {
            setOperationHistory((prev) => ({
              ...prev,
              [targetOperationKey]: [
                ...(prev[targetOperationKey] || []),
                {
                  timestamp: new Date(),
                  user: user.fullname,
                  change: `Changed to custom limitations: ${selectedLimitations.join(", ")}`,
                },
              ],
            }));
          } else {
            setOperationHistory((prev) => ({
              ...prev,
              [targetOperationKey]: [
                ...(prev[targetOperationKey] || []),
                {
                  timestamp: new Date(),
                  user: user.fullname,
                  change: `Changed to custom limitations with no values selected`,
                },
              ],
            }));
          }
        }

        if (hasSetChange) {
          const set = limitationSets.find((cat) => cat.id === operationState.limitationSet);
          setOperationHistory((prev) => ({
            ...prev,
            [targetOperationKey]: [
              ...(prev[targetOperationKey] || []),
              {
                timestamp: new Date(),
                user: user.fullname,
                change: `Set limitation set to: ${set?.name || operationState.limitationSet}`,
              },
            ],
          }));
        }

        if (hasCustomLimitationsChange) {
          // For custom limitations, create a more detailed message
          const currentLimitations = currentState.customLimitations || {};
          const newLimitations = operationState.customLimitations || {};

          // Track changes by group
          Object.keys(groupedLimitations).forEach((group) => {
            const currentLimId = currentLimitations[group];
            const newLimId = newLimitations[group];

            if (currentLimId !== newLimId) {
              const oldLimitation = currentLimId
                ? individualLimitations.find((lim) => lim.id === currentLimId)?.name
                : "None";
              const newLimitation = newLimId ? individualLimitations.find((lim) => lim.id === newLimId)?.name : "None";

              setOperationHistory((prev) => ({
                ...prev,
                [targetOperationKey]: [
                  ...(prev[targetOperationKey] || []),
                  {
                    timestamp: new Date(),
                    user: user.fullname,
                    change: `Changed ${group} limitation from "${oldLimitation}" to "${newLimitation}"`,
                  },
                ],
              }));
            }
          });
        }
      });

      setAircraftOperations((prev) => ({
        ...prev,
        [aircraftId]: {
          ...prev[aircraftId],
          ...Object.fromEntries(operationsToUpdate.map((opLabel) => [opLabel, { ...operationState }])),
        },
      }));

      setShowCopyConfirm(false);
    };

    return (
      <TableRow style={style}>
        <TableCell style={style}>{name.label}</TableCell>
        <TableCell style={styleSwitch}>
          <StyledSwitch
            title="Defines if operation requires aircraft or not"
            checked={operationState.allowed}
            onChange={handleOperationChange("allowed")}
          />
        </TableCell>
        <TableCell style={styleSwitch}>
          <StyledSwitch
            title="Defines if user is under supervision"
            checked={operationState.underSupervision}
            onChange={handleOperationChange("underSupervision")}
            disabled={!operationState.allowed}
          />
        </TableCell>
        <TableCell style={style}>{renderLimitationsInfo()}</TableCell>
        <TableCell style={{ ...style, display: "flex", gap: "8px" }}>
          <IconButton
            size="small"
            onClick={() => setShowHistory({ aircraftId, operationLabel: name.label })}
            disabled={history.length === 0}
          >
            <HistoryIcon />
          </IconButton>
          {isFirst && (
            <IconButton
              size="small"
              onClick={() => setShowCopyConfirm(true)}
              title="Copy these settings to all operations in this aircraft"
            >
              <MoveDown fontSize="small" sx={{ transform: "scaleX(-1)" }} />
            </IconButton>
          )}
        </TableCell>

        {/* Limitations Dialog */}
        <LimitationsDialog
          open={showLimitationsDialog}
          onClose={() => setShowLimitationsDialog(false)}
          onSave={handleSaveLimitations}
          limitationsData={{
            limitationType: operationState.limitationType || "set",
            limitationSet: operationState.limitationSet,
            customLimitations: operationState.customLimitations,
          }}
          companyUsesSets={companyUsesSets}
        />

        {/* Confirmation Dialog */}
        <Dialog open={showCopyConfirm} onClose={() => setShowCopyConfirm(false)} aria-labelledby="copy-confirm-title">
          <DialogTitle id="copy-confirm-title">Confirm Copy to Operations</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">The following settings will be copied:</Typography>
              <Box sx={{ mt: 1, pl: 2 }}>
                <Typography variant="body2">• Allowed: {operationState.allowed ? "Yes" : "No"}</Typography>
                <Typography variant="body2">
                  • Under Supervision: {operationState.underSupervision ? "Yes" : "No"}
                </Typography>

                {operationState.allowed && (
                  <>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      • Limitations:{" "}
                      {companyUsesSets && operationState.limitationType === "set"
                        ? `Set: ${limitationSets.find((cat) => cat.id === operationState.limitationSet)?.name || "None"}`
                        : Object.values(operationState.customLimitations || {}).some((v) => v)
                          ? "Custom limitations"
                          : "No limitations"}
                    </Typography>

                    {operationState.limitationType === "custom" && (
                      <Box sx={{ pl: 2 }}>
                        {Object.entries(operationState.customLimitations || {}).map(([group, limitationId]) => {
                          if (!limitationId) return null;

                          const limitation = individualLimitations.find((lim) => lim.id === limitationId);

                          return limitation ? (
                            <Typography key={group} variant="body2">
                              {group}: {limitation.name}
                            </Typography>
                          ) : null;
                        })}
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>

            {/* Only show operations list when filter is applied */}
            {operationSearchTerm && (
              <Box sx={{ mt: 3, mb: 1 }}>
                <Typography variant="subtitle2">Operations that will be updated:</Typography>
                <Box
                  sx={{
                    mt: 1,
                    maxHeight: "150px",
                    overflow: "auto",
                    border: "1px solid #e0e0e0",
                    borderRadius: "4px",
                    p: 1,
                    bgcolor: "#fff9c4",
                  }}
                >
                  {filteredOperations
                    .filter((op) => op.label !== name.label) // Exclude current operation
                    .map((op) => (
                      <Typography key={op.label} variant="body2" sx={{ py: 0.5 }}>
                        • {op.label}
                      </Typography>
                    ))}
                  {filteredOperations.length <= 1 && (
                    <Typography variant="body2" color="text.secondary" sx={{ fontStyle: "italic" }}>
                      No other operations to update
                    </Typography>
                  )}
                </Box>
                <Typography variant="caption" color="warning.main" sx={{ display: "block", mt: 1 }}>
                  Note: Only filtered operations will be affected. Clear the search to affect all operations.
                </Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowCopyConfirm(false)}>Cancel</Button>
            <Button
              onClick={copyToAllOperations}
              color="primary"
              disabled={filteredOperations.length <= 1} // Disable if no other operations to update
            >
              {operationSearchTerm ? "Apply to Filtered" : "Apply to All"}
            </Button>
          </DialogActions>
        </Dialog>
      </TableRow>
    );
  };

  const renderOperation = (aircraftId) => (operation, index) => {
    return <Operation name={operation} aircraftId={aircraftId} isFirst={index === 0} />;
  };

  const OperationsList = ({ operations, header = true, aircraftId }) => (
    <TableComponent
      header={
        header && [
          { title: "Operation name", style: { width: "45%" } },
          { title: "Yes", style: { width: "10%", textAlign: "center" } },
          { title: "Under supervision", style: { width: "15%", textAlign: "center" } },
          { title: "Limitations", style: { width: "20%" } },
          { title: "Actions", style: { width: "10%" } },
        ]
      }
      WrapperComponent={"div"}
      items={operations}
      renderChildren={renderOperation(aircraftId)}
    />
  );

  const LimitationsDialog = ({ open, onClose, onSave, limitationsData, companyUsesSets }) => {
    const determineInitialType = (data) => {
      if (!companyUsesSets) {
        // When company doesn't use sets, only custom or none is valid
        return Object.values(data.customLimitations || {}).some((v) => v) ? "custom" : "none";
      }

      if (data.limitationType === "none") return "none";
      if (data.limitationType === "set") return "set";

      // If it's custom type but no limitations are selected, treat as 'none'
      if (data.limitationType === "custom") {
        const hasSelectedLimitations = Object.values(data.customLimitations || {}).some((v) => v);
        return hasSelectedLimitations ? "custom" : "none";
      }

      return "none"; // Default to 'none' if unclear
    };

    // Local state to track changes before saving
    const [localLimitationsData, setLocalLimitationsData] = useState({
      limitationType: "none",
      limitationSet: limitationSets[0]?.id || "",
      customLimitations: {},
    });

    // Reset local data when dialog opens with new data
    useEffect(() => {
      if (open) {
        // Initialize with provided data or defaults
        const initialData = {
          limitationType: determineInitialType(limitationsData),
          limitationSet: limitationsData.limitationSet || limitationSets[0]?.id || "",
          customLimitations: { ...(limitationsData.customLimitations || {}) },
        };

        // If company doesn't use sets and this was a set, convert to none
        if (!companyUsesSets && initialData.limitationType === "set") {
          initialData.limitationType = "none";
        }

        // Ensure customLimitations has all groups
        Object.keys(groupedLimitations).forEach((group) => {
          if (initialData.customLimitations[group] === undefined) {
            initialData.customLimitations[group] = null;
          }
        });

        setLocalLimitationsData(initialData);
      }
    }, [open, limitationsData, companyUsesSets]);

    const handleLimitationTypeChange = (event) => {
      setLocalLimitationsData({
        ...localLimitationsData,
        limitationType: event.target.value,
      });
    };

    const handleSetChange = (event) => {
      setLocalLimitationsData({
        ...localLimitationsData,
        limitationSet: event.target.value,
      });
    };

    const handleCustomLimitationChange = (group, limitationId) => {
      setLocalLimitationsData({
        ...localLimitationsData,
        customLimitations: {
          ...localLimitationsData.customLimitations,
          [group]: limitationId === "" ? null : limitationId,
        },
      });
    };

    const handleClearAllLimitations = () => {
      const clearedLimitations = {};
      Object.keys(groupedLimitations).forEach((group) => {
        clearedLimitations[group] = null;
      });

      setLocalLimitationsData({
        ...localLimitationsData,
        customLimitations: clearedLimitations,
        limitationType: "none",
      });
    };

    const handleSave = () => {
      // If nothing is selected in custom mode, treat as "No limitations"
      const dataToSave = { ...localLimitationsData };

      if (dataToSave.limitationType === "custom") {
        const hasSelectedLimitations = Object.values(dataToSave.customLimitations || {}).some((v) => v);
        if (!hasSelectedLimitations) {
          dataToSave.limitationType = "none";
        }
      }

      onSave(dataToSave);
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ pb: 1 }}>Limitations</DialogTitle>
        <DialogContent sx={{ pt: 1 }}>
          {/* Only show limitation type selector if company uses sets */}
          {companyUsesSets && (
            <Box sx={{ mb: 2 }}>
              <FormLabel component="legend" sx={{ fontSize: "0.875rem" }}>
                Limitation Type
              </FormLabel>
              <RadioGroup
                value={localLimitationsData.limitationType}
                onChange={handleLimitationTypeChange}
                row
                sx={{ mt: 0.5 }}
              >
                <FormControlLabel value="none" control={<Radio size="small" />} label="No Limitations" />
                <FormControlLabel value="set" control={<Radio size="small" />} label="Set" />
                <FormControlLabel value="custom" control={<Radio size="small" />} label="Custom" />
              </RadioGroup>
            </Box>
          )}

          {/* Show sets if company uses them and set type is selected */}
          {companyUsesSets && localLimitationsData.limitationType === "set" && (
            <Box sx={{ mb: 2 }}>
              <FormLabel component="legend" sx={{ fontSize: "0.875rem" }}>
                Select Set
              </FormLabel>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 0.5 }}>
                {limitationSets.map((set) => (
                  <Chip
                    key={set.id}
                    label={set.name}
                    size="small"
                    onClick={() => handleSetChange({ target: { value: set.id } })}
                    color={localLimitationsData.limitationSet === set.id ? "primary" : "default"}
                    variant={localLimitationsData.limitationSet === set.id ? "filled" : "outlined"}
                    sx={{ my: 0.25 }}
                  />
                ))}
              </Box>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ display: "block", mt: 0.5, fontSize: "0.75rem" }}
              >
                {limitationSets.find((cat) => cat.id === localLimitationsData.limitationSet)?.description || ""}
              </Typography>
            </Box>
          )}

          {/* Show custom limitations when appropriate */}
          {(!companyUsesSets || localLimitationsData.limitationType === "custom") && (
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
                <FormLabel component="legend" sx={{ fontSize: "0.875rem" }}>
                  {companyUsesSets ? "Select Custom Limitations" : "Select Limitations"}
                </FormLabel>
                <Button size="small" onClick={handleClearAllLimitations} sx={{ fontSize: "0.75rem", p: "2px 8px" }}>
                  Clear All
                </Button>
              </Box>

              <Box>
                {Object.entries(groupedLimitations).map(([group, limitations], index) => (
                  <Box
                    key={group}
                    sx={{
                      mb: index < Object.entries(groupedLimitations).length - 1 ? 1 : 0,
                      pb: index < Object.entries(groupedLimitations).length - 1 ? 1 : 0,
                      borderBottom:
                        index < Object.entries(groupedLimitations).length - 1 ? "1px solid #eaeaea" : "none",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body2" sx={{ mr: 1, width: "90px", color: "text.secondary" }}>
                        {group}:
                      </Typography>
                      <Select
                        fullWidth
                        size="small"
                        value={localLimitationsData.customLimitations[group] || ""}
                        onChange={(e) => handleCustomLimitationChange(group, e.target.value)}
                        displayEmpty
                        sx={{ fontSize: "0.875rem" }}
                      >
                        <MenuItem value="">No limitation</MenuItem>
                        {limitations.map((limitation) => (
                          <MenuItem key={limitation.id} value={limitation.id}>
                            {limitation.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={onClose} size="small">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" size="small">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Initialize expanded sections when assignedList changes
  useEffect(() => {
    const initialExpanded = {};
    assignedList.forEach((aircraft) => {
      initialExpanded[aircraft.id] = operationSearchTerm ? true : false;
    });
    setExpandedSections(initialExpanded);
  }, [assignedList]);

  // Keep sections expanded when operation search is active
  useEffect(() => {
    if (operationSearchTerm) {
      const allExpanded = {};
      assignedList.forEach((aircraft) => {
        allExpanded[aircraft.id] = true;
      });
      setExpandedSections(allExpanded);
    }
  }, [operationSearchTerm]);

  // audit trail
  const HistoryDialog = () => {
    const [editingComments, setEditingComments] = useState({});

    if (!showHistory) return null;

    const { aircraftId, operationLabel } = showHistory;
    const operationKey = `${aircraftId}_${operationLabel}`;
    const history = operationHistory[operationKey] || [];

    // Initialize editing comments from history
    useEffect(() => {
      const initialComments = history.reduce((acc, entry, index) => {
        acc[index] = entry.comment || "";
        return acc;
      }, {});
      setEditingComments(initialComments);
    }, [operationKey]);

    const handleClose = () => {
      // Save all comments when closing
      setOperationHistory((prev) => ({
        ...prev,
        [operationKey]: prev[operationKey].map((entry, index) => ({
          ...entry,
          comment: editingComments[index] || "",
        })),
      }));
      setShowHistory(null);
    };

    // Update local state while typing
    const handleCommentChange = (index, newComment) => {
      setEditingComments((prev) => ({
        ...prev,
        [index]: newComment,
      }));
    };

    // Find the aircraft and its specialization from assignedList
    const aircraft = assignedList.find((a) => a.id === aircraftId);
    const aircraftName = aircraft?.name || "Unknown Aircraft";
    const specialization = aircraft?.specialization?.name;

    return (
      <Dialog open onClose={handleClose} maxWidth="md" fullWidth disableRestoreFocus>
        <DialogTitle>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <FlightIcon sx={{ fontSize: 20 }} />
              {aircraftName}
            </Box>
            {specialization && (
              <Typography variant="body2" color="textSecondary" sx={{ ml: 3, mt: 0.5 }}>
                ↳ {specialization}
              </Typography>
            )}
            <Typography variant="body1" color="textSecondary" sx={{ ml: 3, mt: 0.5 }}>
              • {operationLabel}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <List>
            {history.length === 0 ? (
              <ListItem>
                <ListItemText primary="No history available" />
              </ListItem>
            ) : (
              history.map((entry, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: 2,
                    py: 1,
                  }}
                >
                  <ListItemText
                    primary={entry.change}
                    secondary={`${entry.user} - ${entry.timestamp.toLocaleString()}`}
                  />
                  <TextField
                    size="small"
                    label="Comment"
                    value={editingComments[index] || ""}
                    onChange={(e) => handleCommentChange(index, e.target.value)}
                    variant="outlined"
                    multiline
                    rows={2}
                  />
                </ListItem>
              ))
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <StyledPaper variant="outlined">
        {aircraftList.length ? (
          <Row>
            <Autocomplete
              onChange={(_, value) => setAircraft(value)}
              value={selectedAircraft || {}}
              options={aircraftList}
              size="small"
              inputValue={selectedAircraft?.name || ""}
              isOptionEqualToValue={(_, val) => val.id === selectedAircraft?.id}
              getOptionLabel={(item) => item?.name || ""}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Select Aircraft" />}
            />
          </Row>
        ) : null}

        {Object.keys(selectedAircraft).length && selectedAircraft.specializations.length ? (
          <Row>
            <Autocomplete
              onChange={(_, value) => setSpecialization(value)}
              value={specialization || {}}
              options={selectedAircraft.specializations}
              size="small"
              inputValue={specialization?.name || ""}
              isOptionEqualToValue={(_, val) => val.id === specialization?.id}
              getOptionLabel={(item) => item?.name || ""}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Select Specialization" />}
            />
          </Row>
        ) : null}
        <Button title="Add new aircraft specialization" onClick={addNewAircraftSpecialization}>
          Add
        </Button>
        <Button title="Go back" onClick={() => history.goBack()}>
          Cancel
        </Button>
      </StyledPaper>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          mt: 2,
          p: 1.5,
          bgcolor: "#f5f5f5",
          borderRadius: 1,
          border: "1px dashed #999",
        }}
      >
        <Typography variant="body2" sx={{ mr: 2 }}>
          Demo mode: Company {companyUsesSets ? "uses" : "does not use"} limitation sets
        </Typography>
        <StyledSwitch checked={companyUsesSets} onChange={(e) => setCompanyUsesSets(e.target.checked)} />
      </Box>
      <Box
        sx={{
          mb: 2,
          mt: 2,
          display: "flex",
          gap: 2,
        }}
      >
        <TextField
          fullWidth
          size="small"
          label="Search aircraft"
          value={aircraftSearchTerm}
          onChange={(e) => setAircraftSearchTerm(e.target.value)}
          variant="outlined"
        />
        <TextField
          fullWidth
          size="small"
          label="Search operations"
          value={operationSearchTerm}
          onChange={handleOperationSearch}
          variant="outlined"
        />
      </Box>

      <Typography variant="h4">Assigned aircraft</Typography>
      <Box style={{ overflow: "auto" }}>
        {filteredAssignedList.map((aircraft, index) => (
          <ExpandableSection
            key={`${aircraft.id}-${index}`}
            title={<AircraftTitle title={aircraft.name} aircraftId={aircraft.id} />}
            expanded={operationSearchTerm ? true : expandedSections[aircraft.id] || false}
            onChange={(newValue) => handleSectionToggle(aircraft.id, newValue)}
          >
            <OperationsList operations={filteredOperations} aircraftId={aircraft.id} />
          </ExpandableSection>
        ))}
      </Box>
      <HistoryDialog />
      {/* <TableComponent
        header={[{ title: "Aircraft" }, { title: "Operations" }, { title: "Delete" }]}
        renderChildren={(aircraft) => (
          <TableRow key={aircraft.keyId}>
            <TableCell>
              {aircraft.name}
            </TableCell>
            <TableCell>
              <br />
              <StyledSwitch title="Defines if operation requires aircraft or not" checked={true} />
              <br />
              <StyledSwitch title="Defines if operation requires aircraft or not" checked={false} />
            </TableCell>
            <TableCell>
              <Link onClick={() => removeAircraftSpecialization(aircraft.id)}>
                <Button
                  title="Delete Aircraft and related specializations"
                  icon={<DeleteForeverIcon color="error" />}
                />
              </Link>
            </TableCell>
          </TableRow>
        )}
        items={assignedList}
      /> */}
    </>
  );
};

export default AircraftSpecialization;
