import CertificateService from "../../services/certificate";

const filterByAircraft = (aircraftIds) => (course) =>
  !course.specializationId && course.aircraftTypeId && aircraftIds.includes(course.aircraftTypeId);

const byPosOrOperation =
  (categoryId, positionId, operationId = null) =>
  (course) =>
    course.categoryId === categoryId &&
    (!course.positionId || course.positionId === positionId) &&
    (!course.operationId || !operationId || course.operationId === operationId);

const toDisplayObject = (certificates, metadata) => (aircraftCourses) => {
  const { categoryId, positionId, operationId } = aircraftCourses;
  const title = `${metadata.categories.find((category) => category.id === categoryId).name} → ${
    metadata.positions.find((position) => position.id === positionId).name
  }${operationId ? " → " + metadata.operations.find((operation) => operation.id === operationId).name : ""}`;

  const aircraftObj = {};
  for (const course of aircraftCourses.courses) {
    const aid = course.aircraftTypeId;
    if (!aircraftObj[aid]) aircraftObj[aid] = [];
    const matched = CertificateService.mapToCourse(course, certificates);
    aircraftObj[aid].push(matched);
  }
  for (const customCourse of aircraftCourses.customCourses) {
    const aid = customCourse.aircraftTypeId;
    if (!aircraftObj[aid]) aircraftObj[aid] = [];
    const matched = CertificateService.mapToCustomCourse(customCourse, certificates);
    aircraftObj[aid].push(matched);
  }

  for (const group of aircraftCourses.groups) {
    const aid = group.aircraftTypeId;
    if (!aircraftObj[aid]) aircraftObj[aid] = [];
    const matched = CertificateService.mapToGroup(group, certificates);
    aircraftObj[aid].push(matched);
  }

  const aircraft = Object.entries(aircraftObj).map(([aircraftId, certificates]) => ({
    title: metadata.aircraft.find((a) => a.id === +aircraftId).name,
    status: CertificateService.groupStatus(certificates),
    certificates,
  }));
  return {
    title: aircraft[0].title,
    opTitle: title,
    status: aircraft[0].status,
    certificates: aircraft[0].certificates,
  };
};

const AircraftService = {
  getAircraft(aircraft, description, properties) {
    const mappedAircraft = [];
    const mapped = aircraft.map(({ id }) => id).filter((id) => description.relations.crewAircraft?.includes(id));
    const filterFn = filterByAircraft(mapped);
    const courses = description.courses.filter(filterFn);
    const customCourses = description.customCourses.filter(filterFn);
    const groups = description.groups.filter(filterFn);

    mapped.forEach((aircraftId) => {
      const filterByAircraftFn = filterByAircraft([aircraftId]);
      const coursesByAircraft = courses.filter(filterByAircraftFn);
      const customCoursesByAircraft = customCourses.filter(filterByAircraftFn);
      const groupsByAircraft = groups.filter(filterByAircraftFn);
      const aircraft = {
        aircraft: aircraftId,
        properties: [],
      };
      mappedAircraft.push(aircraft);
      properties.forEach((property) => {
        if (property.positionId) {
          const ffn = byPosOrOperation(property.categoryId, property.positionId, property.operationId);
          const mc = coursesByAircraft.filter(ffn);
          const mcc = customCoursesByAircraft.filter(ffn);
          const mg = groupsByAircraft.filter(ffn);
          if (mc.length || mcc.length || mg.length) {
            aircraft.properties.push({
              categoryId: property.categoryId,
              positionId: property.positionId,
              operationId: property.operationId,
              courses: mc,
              customCourses: mcc,
              groups: mg,
            });
          }
        }
      });
    });
    return mappedAircraft;
  },
  toDisplayObject(aircraft, certificates, metadata) {
    // Get the flattened array of results
    const flatResults = aircraft
      .map((aircraftItem) => aircraftItem.properties.map(toDisplayObject(certificates, metadata)))
      .flat();

    // Group by aircraft title
    const groupedByAircraftTitle = {};

    flatResults.forEach((item) => {
      const aircraftTitle = item.title;

      if (!groupedByAircraftTitle[aircraftTitle]) {
        groupedByAircraftTitle[aircraftTitle] = {
          title: aircraftTitle,
          items: [],
        };
      }

      groupedByAircraftTitle[aircraftTitle].items.push({
        title: item.opTitle,
        certificates: item.certificates,
        status: item.status,
      });
    });

    // Transform to final format
    const result = Object.values(groupedByAircraftTitle).map((group) => {
      return {
        title: group.title,
        status: CertificateService.groupStatus(group.items),
        operations: group.items.map((item) => ({
          title: item.title,
          status: item.status,
          certificates: item.certificates,
        })),
      };
    });

    return result;
  },
};

export default AircraftService;
