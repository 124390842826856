import { uniq } from "ramda";
import request from "./request";
import { getTrainingByCourseId } from "./training";

export async function getSSID() {
  const ssid = await request(`/sts`);
  return ssid;
}

export const openDownloadWindow = async (cert, userId) => {
  const courseId = cert.courseId || String(cert.id).replace(/^cc_/, "");
  const filename = cert.fileName || cert.certificateFile;
  const ssid = await getSSID();
  const custom = /^cc_/.test(cert.id) || cert.isCustom ? "&custom=true" : "";
  window.open(
    `${process.env.REACT_APP_API_URL}/files/${userId}/download/${courseId}/${filename}?ssid=${ssid}${custom}`,
    "_blank",
  );
};

export const openAllDownloadWindow = async (userId) => {
  const ssid = await getSSID();
  window.open(`${process.env.REACT_APP_API_URL}/files/${userId}/download/all?ssid=${ssid}`, "_blank");
};

export const openExportWindow = async (courseId, userId) => {
  const ssid = await getSSID();
  let url = `${process.env.REACT_APP_API_URL}/files/${userId}/export/${courseId}?ssid=${ssid}`;
  window.open(url, "_blank");
};

export const goToCourse = async (courseId, userId) => {
  const { url } = await request.post(`/courses/moodle/enrol/${courseId}`, {
    userId,
  });
  return url;
};
