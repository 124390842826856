import Typography from "@mui/material/Typography";

import ExpandableSection from "../../components/ExpandableSection";
import CertificateList from "../../components/Certificate/CertificateList";
import AircraftService from "./service";
import CourseStatusIcon from "../../components/CourseStatusIcon";

function findHideCourse(course, hideCourses, typeCourse) {
  const newArray = hideCourses.filter(function (el) {
    return (
      el.category_id === course.categoryId &&
      el.operation_id === course.positionId &&
      el.specialization_id === course.operationId &&
      el.type_id === course.aircraftTypeId &&
      el.type === typeCourse
    );
  });
  if (newArray.length > 0) {
    return false;
  } else {
    return true;
  }
}

const Title = ({ item: { title, status } }) => (
  <>
    <CourseStatusIcon status={status} />
    <Typography variant={"body1"} style={{ marginLeft: ".5rem" }}>
      {title}
    </Typography>
  </>
);

const Aircraft = ({ certificates, courses, properties, aircraft, hideAdminCourses }) => {
  const aircraftWithPositions = AircraftService.getAircraft(aircraft, courses, properties);

  if (!hideAdminCourses || hideAdminCourses === null) {
    hideAdminCourses = [];
  }

  courses.courses = courses.courses.filter((elem) => {
    return findHideCourse(elem, hideAdminCourses, "moodle");
  });

  courses.customCourses = courses.customCourses.filter((elem) => {
    return findHideCourse(elem, hideAdminCourses, "custom");
  });

  const aircraftDisplay = AircraftService.toDisplayObject(aircraftWithPositions, certificates, courses.relations);

  return (
    <>
      {aircraftDisplay.map((aircraftObj, index) => (
        <ExpandableSection key={`aircraft-${index}`} title={<Title item={aircraftObj} />}>
          <div>
            {aircraftObj.operations.map((operation, opIndex) => (
              <div key={`operation-${opIndex}`} style={{ marginBottom: "2rem" }}>
                <span style={{ display: "flex", alignItems: "center", margin: "2rem" }}>
                  <CourseStatusIcon status={operation.status} />
                  <Typography variant="h6" style={{ paddingLeft: "1rem" }}>
                    {operation.title}
                  </Typography>
                </span>
                <CertificateList certificates={operation.certificates} header={true} />
              </div>
            ))}
          </div>
        </ExpandableSection>
      ))}
    </>
  );
};

export default Aircraft;
