import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Paper from "@mui/material/Paper";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandableSection = ({ expanded = false, children, title, navId, onChange }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const handleChange = (event, newExpanded) => {
    setIsExpanded(newExpanded);
    if (onChange) {
      onChange(newExpanded);
    }
  };

  return (
    <Paper {...(navId ? { id: navId } : {})} variant="outlined" style={{ marginBottom: "0.5rem" }} elevation={0}>
      <Accordion expanded={isExpanded} onChange={handleChange}>
        {title ? <AccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</AccordionSummary> : null}
        <AccordionDetails style={{ padding: 0, border: 0 }}>{children}</AccordionDetails>
      </Accordion>
    </Paper>
  );
};

ExpandableSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  navId: PropTypes.string,
  children: PropTypes.node,
};

export default ExpandableSection;
