import { useState, useEffect } from "react";

import { Autocomplete, Paper, TextField } from "@mui/material";

import Button from "../../components/Button";
import { StyledSwitch } from "../../components/Base";
import { addOperation } from "../../api/operations";
import { getPositions } from "../../api/positions";
import "./index.scss";

const OPERATION_ERROR_TEXT = "Please enter the name of operation";
const POSITIONS_ERROR_TEXT = "Please select one or more positions";

const OperationsAdd = ({ history }) => {
  const [operationName, setOperationName] = useState("");
  const [positions, setPositions] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);

  const [operationErrorText, setOperationErrorText] = useState(null);
  const [positionsErrorText, setPositionsErrorText] = useState(null);

  useEffect(() => {
    (async () => {
      let newPositions = await getPositions();
      setPositions(newPositions);
    })();
  }, []);

  function handleOperationName(value) {
    setOperationName(value);
    if (!value) return setOperationErrorText(OPERATION_ERROR_TEXT);
    setOperationErrorText(null);
  }

  function handleSelectedPositions(value) {
    setSelectedPositions(value);
    if (!value.length) return setPositionsErrorText(POSITIONS_ERROR_TEXT);
    setPositionsErrorText(null);
  }

  async function addNewOperation() {
    if (!selectedPositions.length) return handleSelectedPositions(selectedPositions);
    if (!operationName) return handleOperationName(operationName);

    const positionIdList = selectedPositions.map((position) => position.id);
    const result = await addOperation(operationName, positionIdList);
    if (result.ok) {
      history.push("/operations");
    }
  }

  return (
    <>
      <h2>Add operation</h2>

      <Paper variant="outlined" className="add-operation" style={{ padding: "1rem 1.5rem" }}>
        <Autocomplete
          className="select-position"
          size="small"
          multiple
          options={positions}
          getOptionLabel={(position) => position.name}
          filterSelectedOptions
          onChange={(event, newPositions) => handleSelectedPositions(newPositions)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select position*"
              size="small"
              error={!!positionsErrorText}
              helperText={positionsErrorText}
            />
          )}
        />
        <TextField
          className="add-field"
          variant="outlined"
          size="small"
          label="Operation name*"
          value={operationName}
          onChange={(event) => handleOperationName(event.target.value)}
          error={!!operationErrorText}
          helperText={operationErrorText}
        />
        <div style={{ marginLeft: "8rem", marginRight: "8rem" }}>
          <span>Requires Aircraft:</span>
          <span>
            <StyledSwitch title="Defines if operation requires aircraft or not" checked={false} />
          </span>
        </div>
        <Button title="Add new operation" onClick={addNewOperation}>
          Add Operation
        </Button>
        <Button title="Go back" onClick={() => history.goBack()}>
          Cancel
        </Button>
      </Paper>
    </>
  );
};

export default OperationsAdd;
