const getPrice = (item, prices, bundlePrices) => {
  if (item.isBundle) {
    return bundlePrices.find((b) => b.id === item.courseId)?.price || 0;
  }
  return prices.find((p) => p.id === item.courseId)?.price || 0;
};
const ShoppingService = {
  mapToDisplayObject(items, prices, bundlePrices, currency, names) {
    return items.map((item) => {
      const price = getPrice(item, prices, bundlePrices);
      return {
        ...item,
        price: price ? `${currency} ${price}` : "Free",
        priceInvoice: price ? price : 0.0,
        name: names.find((n) => n.id === item.userId)?.fullname,
      };
    });
  },
};

export default ShoppingService;
