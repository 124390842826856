import CheckIcon from "@mui/icons-material/Check";
import { PriorityHigh } from "@mui/icons-material";
import { Avatar, styled } from "@mui/material";

import { COURSE_STATUS } from "../../constants";

const DaysSpan = styled("span")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "bold",
}));

const CourseRemainingDaysIcon = ({
  status,
  days,
  purchaseAction,
  underSupervision = false,
  statusPatternStyle = false,
}) => {
  if (!status) return;

  const renderAvatar = (bgcolor, content, underSupervision) => (
    <Avatar
      sx={
        statusPatternStyle
          ? {
              bgcolor,
              position: "relative",
              ...(underSupervision && {
                // White ring (first pseudo-element)
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "85%",
                  height: "85%",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  zIndex: 1,
                },

                // Inner colored circle (second pseudo-element)
                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "75%",
                  height: "75%",
                  borderRadius: "50%",
                  backgroundColor: bgcolor,
                  zIndex: 2,
                },

                // Keep the icon/content visible on top
                "& > *": {
                  position: "relative",
                  zIndex: 3,
                },
              }),
            }
          : {
              bgcolor,
              border: underSupervision
                ? {
                    border: "4px solid #4254bc",
                    boxShadow: "0 0 8px #4254bc",
                  }
                : "",
              cursor: purchaseAction ? "cell" : "default",
            }
      }
      onClick={purchaseAction}
    >
      {content}
    </Avatar>
  );

  if (status === COURSE_STATUS.EXPIRED) {
    return renderAvatar("red", <PriorityHigh />, underSupervision);
  } else if (status === COURSE_STATUS.MISSING) {
    return renderAvatar("red", " ", underSupervision);
  } else if (status === COURSE_STATUS.BUFFER) {
    return renderAvatar("#ffbf00", <DaysSpan>{days}</DaysSpan>, underSupervision);
  } else if (status === COURSE_STATUS.NEVER_EXPIRE) {
    return renderAvatar("green", <CheckIcon />, underSupervision);
  } else {
    return renderAvatar("green", <DaysSpan>{days}</DaysSpan>, underSupervision);
  }
};

export default CourseRemainingDaysIcon;
