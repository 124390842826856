import { useMemo } from "react";
import { useDebounce } from "use-debounce";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import { TableContainer } from "@mui/material";
import { styled } from "@mui/material/styles";

import { formatShortName } from "../../services/formatters";

import "./index.scss";
import CourseStatusTooltip from "../CourseStatusTooltip";
import { COMMON, COURSE_STATUS } from "src/constants";
import { useTranslation } from "react-i18next";
import { useShoppingCart } from "src/context/ShoppingCartContext";

const StickyTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-head": {
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar - 3,
    width: "200px",
    "&:first-of-type": {
      zIndex: theme.zIndex.appBar - 1,
    },
  },
  "&.MuiTableCell-body": {
    left: 0,
    backgroundColor: "white",
    position: "sticky",
    zIndex: theme.zIndex.appBar - 2,
  },
}));

const filterUsersByStatus = (users, searchStatus) => {
  const statusMatches = {
    1: [COURSE_STATUS.OK, COURSE_STATUS.NEVER_EXPIRE],
    2: [COURSE_STATUS.BUFFER],
    3: [COURSE_STATUS.EXPIRED, COURSE_STATUS.MISSING],
    4: [COURSE_STATUS.EXPIRED, COURSE_STATUS.MISSING, COURSE_STATUS.BUFFER],
  };

  return users.map((user) => {
    const [firstPosition, ...courseStatuses] = user.positions;
    const filteredStatuses = courseStatuses.map((courseStat) =>
      statusMatches[searchStatus]?.includes(courseStat.status) ? courseStat : "",
    );

    return {
      id: user.id,
      positions: [firstPosition, ...filteredStatuses],
      roles: user.roles,
    };
  });
};

const filterCoursesByStatus = (courses, users) => {
  let statusIndex = 1; //Indexes of status in user positions array 0 index is user name

  // Map courses inside categories array
  return courses.map((courseCategory) => {
    return courseCategory.map((course) => {
      // Check if any user has a status for this course by match index
      const hasUserWithPosition = users.some((user) => user.positions[statusIndex]);

      // Increment status index for next iteration
      statusIndex++;

      // Return course if any user has status exists, empty string if not
      return hasUserWithPosition ? course : "";
    });
  });
};

const filterUsers = (users, courseIndexes, searchCat) => {
  let filteredUsers = [];

  if (searchCat) {
    users = users.filter((user) => user.roles.find((role) => role.category_id === searchCat));
  }

  filteredUsers = users.map((user) => {
    const [firstPosition, ...courseStatuses] = user.positions;
    const filteredStatuses = courseIndexes ? courseStatuses.filter((_, index) => courseIndexes[index]) : courseStatuses;

    return {
      id: user.id,
      positions: [firstPosition, ...filteredStatuses],
      roles: user.roles,
    };
  });

  return filteredUsers.filter((user) => {
    const [_, ...courseStatuses] = user.positions;
    return courseStatuses.some((status) => status.status);
  });
};

const filterCoursesByCourseCategories = (courses, searchText) => {
  if (!searchText) return courses;

  return courses.map((courseCategory) => {
    const isMatch =
      searchText === COMMON.UNCATIGORIZED
        ? !courseCategory[0].courseCategory
        : courseCategory[0].courseCategory === searchText;

    return isMatch ? courseCategory : courseCategory.map(() => "");
  });
};

const filterCoursesByCourseName = (course, searchOp) => {
  if (!searchOp || !course) return course;

  searchOp = searchOp.toLowerCase();
  return course.fullname.toLowerCase().includes(searchOp) ? course : "";
};

const filterCoursesByCategory = (course, searchCat) => {
  if (!searchCat || !course) return course;

  return course.categories.find((cat) => cat.category_id === searchCat) ? course : "";
};

const applyCourseFilter = ({
  searchStatus,
  searchText,
  searchCat,
  searchOp,
  courses,
  users,
  filterPurchased = false,
}) => {
  if (!searchCat && !searchOp && !searchText && !searchStatus) return { courses, users };

  courses = filterCoursesByCourseCategories(courses, searchText);

  courses = courses.map((courseCategory) => {
    return courseCategory.map((course) => {
      const match = filterCoursesByCategory(course, searchCat);

      if (match && filterCoursesByCourseName(course, searchOp)) {
        return course;
      }
      return "";
    });
  });

  if (searchStatus) {
    users = filterUsersByStatus(users, searchStatus);
    courses = filterCoursesByStatus(courses, users);
  }

  users = filterUsers(users, courses.flat(), searchCat, filterPurchased);

  courses = courses.map((cat) => cat.filter((it) => it)).filter((it) => it.length);

  return {
    courses,
    users,
  };
};

const CrewCourseOverviewTable = ({
  courses: courses_ = [],
  users: users_ = [],
  aircraft = [],
  onUserSelected = () => {},
  onCourseSelected,
  onCategorySelected,
  searchOp: searchOpVal,
  searchRef,
  searchCat,
  searchText,
  searchStatus,
  filterPurchased,
  statusPatternStyle,
}) => {
  const { t } = useTranslation();
  const shoppingCart = useShoppingCart();

  const [searchOp_, { cancel }] = useDebounce(searchOpVal, 400, {
    maxWait: 1500,
  });

  // use debounced value only if the search element is active and the current input value is non-empty
  // otherwise we can just update the display immediately
  let searchOp;
  if (!searchOpVal || document.activeElement !== searchRef) {
    searchOp = searchOpVal;
    // no need for a delayed update as we'll already be using the up to date value
    setTimeout(cancel, 0);
  } else {
    searchOp = searchOp_;
  }

  const { courses, users } = useMemo(
    () =>
      applyCourseFilter({
        searchStatus,
        searchText,
        searchCat,
        searchOp,
        courses: courses_,
        users: users_,
        filterPurchased,
      }),
    [searchOp, courses_, users_],
  );

  const purchaseAction = (data, user) => {
    const course = {
      id: data.course.id,
      name: data.course.fullname,
    };
    shoppingCart.actions.add(course, user.id);
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: "76vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StickyTableCell className="subcat">
                <span>{t("certificate_type")}</span>
              </StickyTableCell>
              {courses.map((cat, index) => (
                <StickyTableCell
                  key={index}
                  className={"subcat"}
                  colSpan={cat.length * aircraft.length}
                  onClick={() => {
                    onCategorySelected(cat[0].courseCategory || COMMON.UNCATIGORIZED);
                  }}
                >
                  <span>{cat[0].courseCategory ?? t("no_theme")}</span>
                </StickyTableCell>
              ))}
            </TableRow>
            <TableRow>
              <StickyTableCell className="cell">
                <span>{t("certificates")}</span>
              </StickyTableCell>
              {courses.map((cat) =>
                cat.map((course) => (
                  <StickyTableCell
                    key={`cert-${course.id}`}
                    align="left"
                    className={"rotate-cell cell"}
                    onClick={() => onCourseSelected(course.fullname)}
                    colSpan={aircraft.length}
                  >
                    <p
                      style={{
                        transform: "rotate(-45deg)",
                        whiteSpace: "nowrap",
                        position: "relative",
                        padding: "20px 0",
                        height: "140px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <span
                        className={"inner"}
                        title={course.fullname}
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                        }}
                      >
                        {course.fullname}
                      </span>
                    </p>
                  </StickyTableCell>
                )),
              )}
            </TableRow>
            <TableRow>
              <StickyTableCell className="cell">
                <span>{t("aircraft")}</span>
              </StickyTableCell>
              {courses.map((cat) =>
                cat.map((course) =>
                  aircraft.map((ac) => (
                    <StickyTableCell key={`aircraft-${course.id}-${ac.id}`} className="rotate-cell cell">
                      <p className={"rotate"}>
                        <span className={"inner"}>{ac.name}</span>
                      </p>
                    </StickyTableCell>
                  )),
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <StickyTableCell
                  style={{ whiteSpace: "nowrap" }}
                  className={"cell"}
                  title={user.positions[0]}
                  onClick={() => onUserSelected(user.positions[0])}
                >
                  {formatShortName(user.positions[0])}
                </StickyTableCell>
                {user.positions.slice(1).map((data, ix) => (
                  <TableCell
                    key={ix}
                    align="center"
                    padding="none"
                    style={{ verticalAlign: "bottom" }}
                    className={
                      "cell" +
                      ((!filterPurchased || !data.purchased) && data.status && data.limitation
                        ? " limitation-cell"
                        : "")
                    }
                  >
                    <CourseStatusTooltip
                      data={!filterPurchased || !data.purchased ? data : {}}
                      purchaseAction={!data.purchased ? () => purchaseAction(data, user) : undefined}
                      statusPatternStyle={statusPatternStyle}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CrewCourseOverviewTable;
