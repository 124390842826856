import { useEffect, useState } from "react";
import { Switch, Route } from "react-router";
import Box from "@mui/material/Box";
import Button from "../../components/Button";
import CurrentUserCrewContext from "../../context/CurrentUserCrewContext";
import Operations from "../../features/operations";
import Positions from "../../features/positions";
import UserNavigation from "../../features/user-navigation";
import CrewCourses, { BundleCourses } from "../../features/crew-courses";
import Aircraft from "../../features/aircraft";
import Specializations from "../../features/specializations";
import UserTitle from "../../components/UserTitle";
import CourseHistory from "../../features/course-history";
import CertificateActions from "../../context/CertificateActions";
import NavigationBack from "../../components/Navigation";
import { getAllCourses } from "../../api/courses";
import { getUserBoughtCourses } from "../../api/payment";

import {
  getUserCertificates,
  getUserProperties,
  getUserAircraft,
  getUserInfo,
  getCrewHideAdminCourses,
} from "../../api/user";

import { getMoodleCourseById } from "../../api/courses";

import { getTrainingDependencies, getTrainingPurchaseCandidates } from "../../api/training";
import { addCoursesHierarchy } from "../../services/trainingHierarchy";

const HEADER_CAPTIONS = {
  positions: "Member Position Courses",
  operations: "Member Operation Courses",
  aircraft: "Member Aircraft Courses",
  specializations: "Member Specialization Courses",
  all: "All Courses",
};

const MembersPage = ({ match, history }) => {
  const [historyItem, setHistoryItem] = useState(null);
  const [certificates, setUserCertificates] = useState(null);
  const [courses, setCourses] = useState(null);
  const [properties, setProperties] = useState(null);
  const [aircraft, setAircraft] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [userBoughtCourses, setUserBoughtCourses] = useState(null);
  const [hideAdminCourses, setHideAdminCourses] = useState(null);
  const [trainingDependencies, setTrainingDependencies] = useState(null);
  const userId = match.params?.userId;
  const crewId = match.params?.crewId;

  useEffect(() => {
    (async () => {
      const [
        courses,
        userInfo,
        properties,
        userAircraft,
        userCertificates,
        trainingPurchaseCandidates,
        userBoughtCourses,
        crewHideAdminCourses,
        trainingDependencies,
      ] = await Promise.all([
        getAllCourses(crewId),
        getUserInfo(userId),
        getUserProperties(crewId, userId),
        getUserAircraft(crewId, userId),
        getUserCertificates(userId, crewId),
        getTrainingPurchaseCandidates(userId, crewId),
        getUserBoughtCourses(userId, crewId),
        getCrewHideAdminCourses(crewId),
        getTrainingDependencies(),
      ]);

      let newUserCertificates = { ...userCertificates };
      for (let key in trainingDependencies) {
        let certificate = newUserCertificates.certificates.find((certificate) => certificate.id == key);
        if (certificate) {
          let dependencies = trainingDependencies[key];
          for (let i = 0; i < dependencies.length; i++) {
            let item = dependencies[i];
            let newCertificate = { ...certificate };
            newCertificate.dependence = 1;
            newCertificate.dependenceId = newCertificate.id;
            const dependenceCourse = await getMoodleCourseById(newCertificate.dependenceId);
            newCertificate.dependenceName = dependenceCourse.name;
            newCertificate.id = item;
            newUserCertificates.certificates.push(newCertificate);
          }
        }
      }

      // Update courses with course highest in the training hierarchy
      const updatedCourses = addCoursesHierarchy(courses, trainingPurchaseCandidates);

      setUserInfo(userInfo);
      setProperties(properties);
      setAircraft(userAircraft);
      setUserCertificates(newUserCertificates);
      setUserBoughtCourses(userBoughtCourses);
      setHideAdminCourses(crewHideAdminCourses);
      setTrainingDependencies(trainingDependencies);
      setCourses(updatedCourses);
    })();
  }, [userId, crewId]);

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Route
          path={`${match.path}/:pageId`}
          children={({ match }) => (
            <h2>
              <NavigationBack /> {HEADER_CAPTIONS[match.params?.pageId] || ""}
            </h2>
          )}
        />
        {userInfo ? <UserTitle id={`${userInfo.id}`} name={userInfo.name} email={userInfo.email} /> : null}
      </Box>
      <Box marginBottom={"1.5rem"}>
        <Route path={`${match.path}/:pageId`} component={UserNavigation} />
      </Box>
      {courses && certificates && properties && aircraft ? (
        <Box style={{ overflow: "auto" }}>
          <CertificateActions.Provider
            value={{
              showHistory(certificate) {
                setHistoryItem(certificate);
              },
              getBoughtCourses() {
                return userBoughtCourses;
              },
            }}
          >
            <CurrentUserCrewContext.Provider value={{ userId: userInfo.id, crewId: crewId }}>
              <Switch>
                <Route path={`${match.path}/positions`}>
                  <Positions certificates={certificates} courses={courses} properties={properties} />
                </Route>
                <Route path={`${match.path}/operations`}>
                  <Operations certificates={certificates} courses={courses} properties={properties} />
                </Route>
                <Route path={`${match.path}/aircraft`}>
                  <Aircraft
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                    aircraft={aircraft}
                    hideAdminCourses={hideAdminCourses}
                  />
                </Route>
                <Route path={`${match.path}/specializations`}>
                  <Specializations
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                    aircraft={aircraft}
                  />
                </Route>
                <Route path={`${match.path}/all`}>
                  <CrewCourses crewId={crewId} certificates={certificates} courses={courses} properties={properties} />
                </Route>
                <Route path={`${match.path}/bundles`}>
                  <BundleCourses
                    crewId={crewId}
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                  />
                </Route>
              </Switch>
            </CurrentUserCrewContext.Provider>
          </CertificateActions.Provider>
        </Box>
      ) : null}
      {historyItem ? (
        <CourseHistory
          userId={userId}
          certificate={historyItem}
          userCertificates={certificates}
          courses={courses}
          onClose={() => setHistoryItem(null)}
        />
      ) : null}
      <Button title="Go back" type="button" onClick={() => history.push(`/crew/${crewId}/members`)}>
        Return to members
      </Button>
    </>
  );
};

export default MembersPage;
