import moment from "moment";
import { COURSE_STATUS } from "../constants";
import { formatDate, parseDateString } from "./formatters";
import { isValidDate } from "src/utils/isValidDate";

const today = moment();
const validObj = (course) => moment(course.completed_date).endOf("month").add(course.valid_period, "months");

export const status = (course) => {
  if (!course) return COURSE_STATUS.MISSING;

  // Certificate never expire
  if (!course.valid_period) return COURSE_STATUS.NEVER_EXPIRE;

  let validDue = validObj(course);
  if (course.course_valid_until) {
    validDue = moment(course.course_valid_until);
  }

  return calcStatus(validDue, course.buffer_zone);
};

const calcStatus = (momentDate, bufferZone) => {
  if (momentDate.diff(today) < 0) {
    return COURSE_STATUS.EXPIRED;
  }
  if (bufferZone && momentDate.diff(today, "months") < bufferZone) {
    return COURSE_STATUS.BUFFER;
  }

  return COURSE_STATUS.OK;
};

export const ok = (course) => statusOk(status(course));

const statusOk = (status) => status === COURSE_STATUS.OK || status === COURSE_STATUS.BUFFER;

export const validUntil = (course) => {
  if (course.course_valid_until) {
    return formatDate(course.course_valid_until);
  }
  if (course.valid_period === 0) return COURSE_STATUS.NEVER_EXPIRE;

  return validObj(course).valueOf();
};

export const getBufferZoneStartDate = (course) => {
  if (course && course.valid_period) {
    const validDue = moment(course.valid, "DD.MM.YYYY");
    return formatDate(validDue.subtract(course.buffer, "months"));
  }
  return null;
};

export const getRemainingValidityDays = (course) => {
  if (course && course.valid_period) {
    const validDue = moment(course.valid, "DD.MM.YYYY");
    return validDue.diff(today, "days") + 1;
  }
  return null;
};

export const completed = (course) => {
  if (!course.completed_date) return null;
  return formatDate(course.completed_date);
};

export const expires = (course) => {
  if (course.valid_period === 0) return COURSE_STATUS.NEVER_EXPIRE;
  if (!course.valid_period) return "";
  return course.valid_period + " Month";
};

export const sortByExpiration = (certificates) =>
  certificates.sort((a, b) => {
    if (a.validUntil || b.validUntil) {
      if (!a.validUntil) return -1;
      if (!b.validUntil) return 1;
      return a.validUntil > b.validUntil ? -1 : 1;
    }
    if (a.valid || b.valid) {
      return parseDateString(b.valid) - parseDateString(a.valid);
    }
  });

export const sortByCompletionAsc = (certificates) => certificates.sort((a, b) => (a.completed > b.completed ? 1 : -1));

const certStatus = (cert) => ({
  course_id: cert.course_id || (cert.custom_course_id ? `cc_${cert.custom_course_id}` : null),
  name: cert.fullname,
  expires: expires(cert),
  status: status(cert),
  valid: validUntil(cert),
  valid_period: cert.valid_period,
  completed: completed(cert),
  buffer: cert.buffer_zone,
  __hard_valid: !!cert.course_valid_until,
  ok: ok(cert),
});

export const mapUserCertificates = (user) => {
  const userCourses = user.courses.map(certStatus);
  const customCourses = user.customCourses.map(certStatus);

  return {
    courses: userCourses,
    customCourses: customCourses,
  };
};

const calculateRefreshers = (course, cert, user) => {
  const refreshers = sortByCompletionAsc(user.courses.filter((c) => course.refreshers.includes(c.course_id)));
  let prev = cert;
  const r = [];
  for (let refresher of refreshers) {
    if (!refresher.__hard_valid) {
      const diff = moment(prev.valid).diff(refresher.completed, "month");
      if (diff >= 0 && diff < prev.buffer) {
        const newValid = moment(prev.valid).add(refresher.valid_period, "months");
        const status = calcStatus(newValid, refresher.buffer);
        refresher.valid = newValid.format("DD.MM.YYYY");
        refresher.status = status;
        refresher.ok = statusOk(status);
      }
    }
    if (refresher.ok) {
      r.push(refresher);
    }
    prev = refresher;
  }

  return r.reverse();
};

export const userCourseMatch = (course, user) => {
  const userCourses = user.courses.filter((c) => c.course_id === course.course_id);

  const cert = sortByExpiration(userCourses)[0];
  if (cert) {
    if (course.refreshers.length) {
      const refreshers = calculateRefreshers(course, cert, user);
      const refresher = refreshers[0];
      cert.refreshers = refreshers;
      if (refresher) {
        cert.status = refresher.status;
        cert.expires = refresher.expires;
        cert.valid = refresher.valid;
        cert.ok = refresher.ok;
      }
    }
    return cert;
  } else {
    return {
      course_id: course.course_id,
      status: COURSE_STATUS.MISSING,
    };
  }
};

export const userGroups = (grp, user) => {
  const group = {
    name: grp.group_name,
    course_id: grp.group_id,
    status: COURSE_STATUS.MISSING,
    valid: COURSE_STATUS.MISSING,
    expires: "",
    group_id: grp.group_id,
    group_name: grp.group_name,
  };
  const match = sortByExpiration(user.courses.filter((c) => grp.courses.includes(c.course_id)));

  const course = match[0];

  if (course) {
    group.name = course.name;
    group.course_id = course.course_id;
    group.status = course.status;
    group.expires = course.expires;
    group.valid = course.valid;
  }

  return group;
};

const certificateStatus = (certificate) => {
  let validUntil = null;

  if (certificate.validPeriod) {
    validUntil = moment(certificate.validPeriod);
  } else if (certificate.defaultValidPeriod) {
    validUntil = moment(certificate.completedDate).add(certificate.defaultValidPeriod, "months").endOf("month");
  }

  let certificateStatus = certificate.defaultValidPeriod
    ? calcStatus(validUntil, certificate.bufferZone)
    : COURSE_STATUS.OK;

  return {
    ...certificate,
    status: certificateStatus,
    validUntil: validUntil?.valueOf(),
    approveStatus: certificate.approveStatus || certificate.status || null,
  };
};

const CertificateService = {
  map(userCertificates) {
    return {
      certificates: userCertificates.certificates.map(certificateStatus),
      customCertificates: userCertificates.customCertificates.map(certificateStatus),
      courseHierarchyCandidates: userCertificates.courseHierarchyCandidates,
    };
  },
  refreshers(refreshers, certificates) {
    const ret = [];
    for (const refresher of refreshers) {
      const certificatesMatched = certificates.filter((cert) => cert.id === refresher.id);
      if (certificatesMatched.length > 0) {
        certificatesMatched.forEach((certificate) => {
          ret.push({
            ...refresher,
            status: certificate.status,
            validPeriod: certificate.validPeriod,
            validUntil: certificate.validUntil,
          });
        });
      }
    }

    return ret;
  },
  getUserRefreshers(course, certificates) {
    return course.refreshers.map((refresher) => {
      const certs = certificates.filter((cert) => cert.id === refresher.id);
      if (!certs.length) {
        return {
          ...refresher,
          status: COURSE_STATUS.MISSING,
        };
      } else {
        const latest = certs.sort((a, b) => b.validUntil - a.validUntil)[0];
        return {
          ...refresher,
          status: latest?.status,
          validUntil: latest?.validUntil,
          fileName: latest?.certificateFile,
        };
      }
    });
  },
  mapToCourse(course, { certificates }) {
    const matched = sortByExpiration(certificates.filter((certificate) => certificate.id === course.id));
    const lastValidCertificate = matched.sort((a, b) => b.validUntil - a.validUntil)[0];

    if (lastValidCertificate && course.refreshers?.length) {
      const obj = {
        id: course.id,
        uploadId: lastValidCertificate.uploadId,
        name: course.name,
      };

      const refreshers = this.getUserRefreshers(course, certificates).sort((a, b) => b.id - a.id);
      let lastValid = refreshers[0];
      const latest = refreshers[0];
      let res = [];
      let i = 0;

      while (lastValid?.status === COURSE_STATUS.MISSING && i <= refreshers.length) {
        lastValid = refreshers[i++];
      }

      if (
        !lastValid ||
        (lastValidCertificate.status === COURSE_STATUS.OK && lastValid?.status !== COURSE_STATUS.OK) ||
        lastValidCertificate.validUntil > lastValid?.validUntil
      ) {
        if (lastValid) res.push(lastValid);
        lastValid = lastValidCertificate;
      } else {
        if (lastValid.status === COURSE_STATUS.BUFFER) {
          res.push(latest);
        } else {
          res.push(lastValid);
        }
      }

      refreshers.forEach((latest) => {
        if (latest.isActive == 1) {
          if (lastValid && lastValid.status !== COURSE_STATUS.OK && lastValid.id !== latest?.id) {
            res.push(latest);
            latest.uploadable = true;
          }
        }
      });

      if (!res.length) {
        obj.price = course.price;
      }

      let unique = [...new Set(res)];

      const isActiveCourses = unique.filter((c) => c.isActive == 1);

      //remove expired courses if there is active courses
      if (lastValid?.status === COURSE_STATUS.EXPIRED && isActiveCourses.length > 0) {
        unique = isActiveCourses;
      }

      return {
        ...obj,
        status: lastValid?.status || COURSE_STATUS.MISSING,
        validUntil: lastValid?.validUntil,
        latestRefresherId: lastValid?.id,
        fileName: lastValid?.certificateFile,
        validPeriod: course?.validPeriod,
        price: course?.price,
        type: course?.type,
        visible: course?.visible,
        refreshers: unique,
        dependence: lastValid?.dependence,
        dependenceId: lastValid?.dependenceId,
        dependenceName: lastValid?.dependenceName,
        highestInTrainingHierarchyCourseId: course?.highestInTrainingHierarchyCourseId,
        highestInTrainingHierarchyCourseName: course?.highestInTrainingHierarchyCourseName,
        passedByCourseId: lastValidCertificate?.passedByCourseId,
        passedByCourseName: lastValidCertificate?.passedByCourseName,
        passedByTrainingId: lastValidCertificate?.passedByTrainingId,
      };
    }

    return {
      id: course.id,
      uploadId: course.uploadId,
      name: course.name,
      price: course.price,
      type: course.type,
      uploadable: true,
      visible: course.visible,
      status: lastValidCertificate?.status || COURSE_STATUS.MISSING,
      validUntil: lastValidCertificate?.validUntil,
      validPeriod: lastValidCertificate?.defaultValidPeriod,
      fileName: lastValidCertificate?.certificateFile,
      highestInTrainingHierarchyCourseId: course?.highestInTrainingHierarchyCourseId,
      highestInTrainingHierarchyCourseName: course?.highestInTrainingHierarchyCourseName,
      passedByCourseId: lastValidCertificate?.passedByCourseId,
      passedByCourseName: lastValidCertificate?.passedByCourseName,
      passedByTrainingId: lastValidCertificate?.passedByTrainingId,
    };
  },
  mapToCustomCourse(customCourse, { customCertificates }) {
    const matched = sortByExpiration(customCertificates.filter((certificate) => certificate.id === customCourse.id));

    const certificate = matched.sort((a, b) => b.validUntil - a.validUntil)[0];
    return {
      id: `cc_${customCourse.id}`,
      uploadId: certificate?.uploadId,
      name: customCourse.name,
      uploadable: true,
      status: certificate?.status || COURSE_STATUS.MISSING,
      validUntil: certificate?.validUntil,
      validPeriod: certificate?.defaultValidPeriod,
      fileName: certificate?.certificateFile,
    };
  },
  mapToGroup(group, { certificates }) {
    let matched = [];
    for (const course of group.courses) {
      matched.push(CertificateService.mapToCourse(course, { certificates }));
    }
    matched = sortByExpiration(matched);
    const valid =
      matched.find((certificate) => certificate.status === COURSE_STATUS.OK) ||
      matched.find((certificate) => certificate.status === COURSE_STATUS.BUFFER) ||
      matched.find((m) => m.status !== COURSE_STATUS.MISSING);

    const latest = group.courses.filter((c) => c.visible).sort((c1, c2) => c2.order - c1.order)[0];

    const groupObj = {
      id: valid?.id,
      name: valid?.name,
      status: valid?.status || COURSE_STATUS.MISSING,
      uploadable: true,
      validUntil: valid?.validUntil,
      validPeriod: valid?.validPeriod,
      fileName: valid?.certificateFile,
      visible: latest?.visible,
      group: {
        id: group.groupId,
        name: group.name,
        categoryId: group.categoryId,
        positionId: group.positionId,
        operationId: group.operationId,
        courses: group.courses,
        latest,
      },
    };

    if (groupObj.status !== COURSE_STATUS.OK) {
      // we need a latest one here (not sure exactly why though), even if it may be non-visible
      const latest_ = latest ?? group.courses.sort((a, b) => b.order - a.order)[0];
      groupObj.id = latest_.id;
      groupObj.price = latest_.price;
      groupObj.name = latest_.name;
    }

    return groupObj;
  },
  groupStatus(certificates) {
    let status = COURSE_STATUS.OK;
    for (const certificate of certificates) {
      if (certificate.status === COURSE_STATUS.BUFFER) {
        status = COURSE_STATUS.BUFFER;
      }
      if (certificate.status === COURSE_STATUS.MISSING || certificate.status === COURSE_STATUS.EXPIRED) {
        return COURSE_STATUS.MISSING;
      }
    }
    return status;
  },
  userCustomCertificateMatch(customCourse, user) {
    const id = `cc_${customCourse.custom_course_id}`;
    const ucc = user.customCourses.filter((c) => c.course_id === id);

    const sorted = sortByExpiration(ucc);
    const valid = sorted.find(ok);

    if (!ucc.length) {
      return {
        course_id: id,
        name: customCourse.fullname,
        status: COURSE_STATUS.MISSING,
      };
    } else if (!valid) {
      return sorted[0];
    }

    return valid;
  },
};

export default CertificateService;
