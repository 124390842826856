import { FC, useState, useRef, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

import {
  Box,
  Checkbox,
  Typography,
  Paper,
  Grid,
  Chip,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  IconButton,
  Switch,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import ListIcon from "@mui/icons-material/List";
import CategoryIcon from "@mui/icons-material/Category";

interface MatchParams {
  crewId: string;
}

// Limitation types
type LimitationType = "number" | "list" | "boolean";

// Enhanced limitation interface
interface Limitation {
  id: string;
  name: string;
  enabled: boolean;
  type: LimitationType;
  unit?: string;
  operator?: string;
  predefined: boolean;
  values: string[]; // Possible values for this limitation
  inSets: boolean; // Whether this limitation is used in sets
  canBeUnlimited: boolean; // Whether this limitation can have "no limit" option
}

// Set limitation value with unlimited flag
interface LimitationValue {
  limitationId: string;
  value: string;
  unlimited?: boolean; // Whether this limitation has no limit in this set
}

// Set with specific limitation values
interface Set {
  id: string;
  name: string;
  key: string; // number or max 2 letters
  limitationValues: LimitationValue[];
}

// Common units for different measurements
const commonUnits = [
  { value: "kt", label: "Knots (kt)" },
  { value: "km", label: "Kilometers (km)" },
  { value: "m", label: "Meters (m)" },
  { value: "ft", label: "Feet (ft)" },
  { value: "min", label: "Minutes (min)" },
  { value: "%", label: "Percent (%)" },
  { value: "kg", label: "Kilograms (kg)" },
  { value: "L", label: "Liters (L)" },
];

// Common operators
const operators = [
  { value: "<", label: "Less than (<)" },
  { value: ">", label: "Greater than (>)" },
];

const Limitations: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { crewId } = match.params;

  // Global settings
  const [useSets, setUseSets] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);

  // limitations & sets
  const [limitations, setLimitations] = useState<Limitation[]>([]);
  const [sets, setSets] = useState<Set[]>([]);

  const preparedLimitations = [
    {
      name: "Wind",
      type: "number" as LimitationType,
      unit: "kt",
      operator: "<",
      values: ["20", "25", "30"],
      canBeUnlimited: true,
    },
    {
      name: "Gusts",
      type: "number" as LimitationType,
      unit: "kt",
      operator: "<",
      values: ["10", "15", "20"],
      canBeUnlimited: true,
    },
    {
      name: "Visibility",
      type: "number" as LimitationType,
      unit: "km",
      operator: ">",
      values: ["3", "5", "8", "10"],
      canBeUnlimited: true,
    },
    {
      name: "Ceiling",
      type: "number" as LimitationType,
      unit: "ft",
      operator: ">",
      values: ["800", "1000", "1500", "2000"],
      canBeUnlimited: true,
    },
    {
      name: "Pax",
      type: "list" as LimitationType,
      values: ["None", "VFR only", "Max"],
      canBeUnlimited: false,
    },
    {
      name: "Performance",
      type: "list" as LimitationType,
      values: ["HOGE", "HIGE", "Limited"],
      canBeUnlimited: false,
    },
    {
      name: "Night Operations",
      type: "boolean" as LimitationType,
      values: ["Yes"],
      canBeUnlimited: false,
    },
  ];

  const handleLoadData = () => {
    setLimitations([
      {
        id: "1",
        name: "Wind",
        enabled: true,
        type: "number",
        unit: "kt",
        operator: "<",
        predefined: true,
        values: ["20", "25", "30"],
        inSets: true,
        canBeUnlimited: true,
      },
      {
        id: "2",
        name: "Gusts",
        enabled: true,
        type: "number",
        unit: "kt",
        operator: "<",
        predefined: true,
        values: ["10", "15", "20"],
        inSets: true,
        canBeUnlimited: true,
      },
      {
        id: "3",
        name: "Visibility",
        enabled: true,
        type: "number",
        unit: "km",
        operator: ">",
        predefined: true,
        values: ["3", "5", "8", "10"],
        inSets: true,
        canBeUnlimited: true,
      },
      {
        id: "4",
        name: "Ceiling",
        enabled: true,
        type: "number",
        unit: "ft",
        operator: ">",
        predefined: true,
        values: ["800", "1000", "1500", "2000"],
        inSets: true,
        canBeUnlimited: true,
      },
      {
        id: "5",
        name: "Pax",
        enabled: true,
        type: "list",
        predefined: true,
        values: ["None", "VFR only", "Max"],
        inSets: false,
        canBeUnlimited: false,
      },
      {
        id: "6",
        name: "Fuel",
        enabled: true,
        type: "number",
        unit: "min",
        operator: ">",
        predefined: true,
        values: ["20", "30", "45", "60"],
        inSets: true,
        canBeUnlimited: true,
      },
      {
        id: "7",
        name: "Performance",
        enabled: true,
        type: "list",
        predefined: true,
        values: ["HOGE", "HIGE", "Limited"],
        inSets: true,
        canBeUnlimited: false,
      },
      {
        id: "8",
        name: "Night Operations",
        enabled: true,
        type: "boolean",
        predefined: true,
        values: ["Yes", "No"],
        inSets: false,
        canBeUnlimited: false,
      },
    ]);
    setSets([
      {
        id: "1",
        name: "Set 1",
        key: "1",
        limitationValues: [
          { limitationId: "1", value: "" },
          { limitationId: "2", value: "" },
          { limitationId: "3", value: "" },
          { limitationId: "4", value: "" },
          { limitationId: "6", value: "" },
          { limitationId: "7", value: "" },
        ],
      },
      {
        id: "2",
        name: "Set 2",
        key: "2",
        limitationValues: [
          { limitationId: "1", value: "30" },
          { limitationId: "2", value: "20" },
          { limitationId: "3", value: "3" },
          { limitationId: "4", value: "800" },
          { limitationId: "6", value: "20" },
          { limitationId: "7", value: "HIGE" },
        ],
      },
      {
        id: "3",
        name: "Set 3",
        key: "3",
        limitationValues: [
          { limitationId: "1", value: "25" },
          { limitationId: "2", value: "15" },
          { limitationId: "3", value: "5" },
          { limitationId: "4", value: "1000" },
          { limitationId: "6", value: "30" },
          { limitationId: "7", value: "HOGE" },
        ],
      },
      {
        id: "4",
        name: "Set 4",
        key: "4",
        limitationValues: [
          { limitationId: "1", value: "20" },
          { limitationId: "2", value: "10", unlimited: false },
          { limitationId: "3", value: "5" },
          { limitationId: "4", value: "1500" },
          { limitationId: "6", value: "45" },
          { limitationId: "7", value: "HOGE" },
        ],
      },
    ]);
    setUseSets(true);
  };

  // Form states
  const [showPreparedLimitations, setShowPreparedLimitations] = useState<boolean>(false);
  const [selectedPreparedLimitations, setSelectedPreparedLimitations] = useState<number[]>([]);
  const [showAddLimitationForm, setShowAddLimitationForm] = useState<boolean>(false);
  const [newLimitation, setNewLimitation] = useState<string>("");
  const [limitationType, setLimitationType] = useState<LimitationType>("number");
  const [limitationUnit, setLimitationUnit] = useState<string>("");
  const [limitationOperator, setLimitationOperator] = useState<string>("<");

  const [newSet, setNewSet] = useState<string>("");
  const [newSetKey, setNewSetKey] = useState<string>("");

  // Dialog states
  const [limitationDialogOpen, setLimitationDialogOpen] = useState<boolean>(false);
  const [selectedLimitation, setSelectedLimitation] = useState<string | null>(null);
  const [newLimitationValue, setNewLimitationValue] = useState<string>("");
  const [listValues, setListValues] = useState<string[]>([]);
  const [newListItem, setNewListItem] = useState<string>("");

  // Edit mode states for limitation values in sets
  const [isTableEditable, setIsTableEditable] = useState<boolean>(false);
  const [showAddSetForm, setShowAddSetForm] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<{ setId: string; limitationId: string } | null>(null);
  const [editValue, setEditValue] = useState<string>("");
  const [editUnlimited, setEditUnlimited] = useState<boolean>(false);

  // Handle tab change
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // Modify the handleAddPreparedLimitation function to handle multiple selections
  const handleAddPreparedLimitations = () => {
    const newLimitationsData = selectedPreparedLimitations.map((index, arrayIndex) => {
      const prepared = preparedLimitations[index];
      const newLimitationId = (limitations.length + arrayIndex + 1).toString();

      const newLimitationObj: Limitation = {
        id: newLimitationId,
        name: prepared.name,
        enabled: true,
        type: prepared.type,
        predefined: false,
        // For boolean type, just use ["Yes"] as the default value
        values: prepared.type === "boolean" ? ["Yes"] : [...prepared.values],
        inSets: useSets,
        canBeUnlimited: prepared.canBeUnlimited,
      };

      if (prepared.type === "number") {
        newLimitationObj.unit = prepared.unit;
        newLimitationObj.operator = prepared.operator;
      }

      return {
        limitation: newLimitationObj,
        id: newLimitationId,
        type: prepared.type, // Pass the type to use below
      };
    });

    // Add all new limitations
    setLimitations([...limitations, ...newLimitationsData.map((data) => data.limitation)]);

    // If sets are enabled, add all new limitations to all sets
    if (useSets && sets.length > 0) {
      setSets(
        sets.map((set) => ({
          ...set,
          limitationValues: [
            ...set.limitationValues,
            ...newLimitationsData.map((data) => ({
              limitationId: data.id,
              // For boolean type, default to "Yes", otherwise empty string
              value: data.type === "boolean" ? "Yes" : "",
            })),
          ],
        })),
      );
    }

    setShowPreparedLimitations(false);
    setSelectedPreparedLimitations([]);

    // Hide the form after adding
    setShowAddLimitationForm(false);
  };

  // Add a function to toggle selection of a prepared limitation
  const togglePreparedLimitation = (index: number) => {
    setSelectedPreparedLimitations((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };

  const toggleAllPreparedLimitations = () => {
    if (selectedPreparedLimitations.length === preparedLimitations.length) {
      // If all are selected, deselect all
      setSelectedPreparedLimitations([]);
    } else {
      // Otherwise, select all
      setSelectedPreparedLimitations(preparedLimitations.map((_, index) => index));
    }
  };

  // Add new limitation
  const handleAddLimitation = () => {
    if (newLimitation.trim() !== "") {
      const newLimitationId = (limitations.length + 1).toString();
      const newLimitationObj: Limitation = {
        id: newLimitationId,
        name: newLimitation,
        enabled: true,
        type: limitationType,
        predefined: false,
        values: limitationType === "boolean" ? ["Yes"] : [], // Default boolean to "Yes"
        inSets: useSets,
        canBeUnlimited: limitationType === "number",
      };

      if (limitationType === "number") {
        newLimitationObj.unit = limitationUnit;
        newLimitationObj.operator = limitationOperator;
      }

      setLimitations([...limitations, newLimitationObj]);

      // If sets are enabled, add this limitation to all sets
      if (useSets && sets.length > 0) {
        setSets(
          sets.map((set) => ({
            ...set,
            limitationValues: [
              ...set.limitationValues,
              {
                limitationId: newLimitationId,
                value: limitationType === "boolean" ? "Yes" : "", // Default boolean to "Yes"
              },
            ],
          })),
        );
      }

      // Reset form
      setNewLimitation("");
      setLimitationType("number");
      setLimitationUnit("");
      setLimitationOperator("<");

      // Hide the form after adding
      setShowAddLimitationForm(false);
    }
  };

  // Open limitation values dialog
  const openLimitationValuesDialog = (limitationId: string) => {
    const limitation = limitations.find((lim) => lim.id === limitationId);
    if (limitation && limitation.type !== "boolean") {
      setSelectedLimitation(limitationId);

      if (limitation.type === "list") {
        setListValues([...limitation.values]);
      }

      setLimitationDialogOpen(true);
    }
  };

  // Add value to limitation
  const addValueToLimitation = () => {
    if (selectedLimitation && newLimitationValue.trim() !== "") {
      // Split by comma and trim each value
      const values = newLimitationValue
        .split(",")
        .map((val) => val.trim())
        .filter((val) => val !== "");

      setLimitations(
        limitations.map((limitation) =>
          limitation.id === selectedLimitation
            ? { ...limitation, values: [...limitation.values, ...values] }
            : limitation,
        ),
      );
      setNewLimitationValue("");
    }
  };

  // Add list item
  const addListItem = () => {
    if (newListItem.trim() !== "") {
      setListValues([...listValues, newListItem]);
      setNewListItem("");
    }
  };

  // Save list values
  const saveListValues = () => {
    if (selectedLimitation) {
      setLimitations(
        limitations.map((limitation) =>
          limitation.id === selectedLimitation ? { ...limitation, values: listValues } : limitation,
        ),
      );
    }
  };

  // Delete value from limitation
  const deleteValueFromLimitation = (limitationId: string, valueIndex: number) => {
    setLimitations(
      limitations.map((limitation) =>
        limitation.id === limitationId
          ? {
              ...limitation,
              values: limitation.values.filter((_, index) => index !== valueIndex),
            }
          : limitation,
      ),
    );
  };

  // Delete list item
  const deleteListItem = (index: number) => {
    setListValues(listValues.filter((_, i) => i !== index));
  };

  // Close limitation values dialog
  const closeLimitationDialog = () => {
    if (selectedLimitation) {
      const limitation = limitations.find((lim) => lim.id === selectedLimitation);
      if (limitation && limitation.type === "list") {
        saveListValues();
      }
    }
    setLimitationDialogOpen(false);
    setSelectedLimitation(null);
    setNewLimitationValue("");
    setListValues([]);
  };

  // Add new set
  const handleAddSet = () => {
    if (newSet.trim() !== "") {
      // Create limitation values for limitations that should be in sets
      const newLimitationValues = limitations
        .filter((lim) => lim.inSets)
        .map((limitation) => ({
          limitationId: limitation.id,
          value: "",
        }));

      setSets([
        ...sets,
        {
          id: (sets.length + 1).toString(),
          name: newSet,
          key: newSetKey || (sets.length + 1).toString(),
          limitationValues: newLimitationValues,
        },
      ]);

      setNewSet("");
      setNewSetKey("");
    }
  };

  // Toggle limitation in sets
  const toggleLimitationInSets = (id: string) => {
    const limitation = limitations.find((lim) => lim.id === id);
    if (!limitation) return;

    // Update the limitation
    setLimitations(limitations.map((lim) => (lim.id === id ? { ...lim, inSets: !lim.inSets } : lim)));

    // If adding to sets, add this limitation to all sets
    if (!limitation.inSets) {
      setSets(
        sets.map((set) => ({
          ...set,
          limitationValues: [...set.limitationValues, { limitationId: id, value: "" }],
        })),
      );
    } else {
      // If removing from sets, remove this limitation from all sets
      setSets(
        sets.map((set) => ({
          ...set,
          limitationValues: set.limitationValues.filter((value) => value.limitationId !== id),
        })),
      );
    }
  };

  // Delete limitation
  const deleteLimitation = (id: string) => {
    setLimitations(limitations.filter((limitation) => limitation.id !== id));

    // Remove this limitation from all sets
    setSets(
      sets.map((set) => ({
        ...set,
        limitationValues: set.limitationValues.filter((value) => value.limitationId !== id),
      })),
    );
  };

  // Delete set
  const deleteSet = (id: string) => {
    setSets(sets.filter((set) => set.id !== id));
  };

  // Start editing a limitation value in a set
  const startEditingLimitationValue = (setId: string, limitationId: string) => {
    const set = sets.find((set) => set.id === setId);
    if (set) {
      const limitationValue = set.limitationValues.find((val) => val.limitationId === limitationId);
      const limitation = getLimitation(limitationId);

      if (limitationValue && limitation) {
        // For boolean type, toggle the value directly instead of opening dropdown
        if (limitation.type === "boolean") {
          const newValue = limitationValue.value === "Yes" ? "No" : "Yes";
          setSets(
            sets.map((s) =>
              s.id === setId
                ? {
                    ...s,
                    limitationValues: s.limitationValues.map((value) =>
                      value.limitationId === limitationId
                        ? {
                            ...value,
                            value: newValue,
                          }
                        : value,
                    ),
                  }
                : s,
            ),
          );
          return;
        }

        setEditMode({ setId, limitationId });

        // If value is empty or value is "AFM" (which is a placeholder), prefer unlimited if available
        if (!limitationValue.value || limitationValue.value === "" || limitationValue.value === "AFM") {
          if (limitation.canBeUnlimited) {
            setEditValue("unlimited");
            setEditUnlimited(true);
          } else if (limitation.values.length > 0) {
            // Use the least critical value as before
            if (limitation.type === "number") {
              const numericValues = limitation.values.map((v) => parseFloat(v)).filter((v) => !isNaN(v));
              if (numericValues.length > 0) {
                const value =
                  limitation.operator === ">" || limitation.operator === "≥"
                    ? Math.min(...numericValues)
                    : Math.max(...numericValues);
                setEditValue(value.toString());
              } else {
                setEditValue(limitation.values[0]);
              }
            } else {
              setEditValue(limitation.values[limitation.values.length - 1]);
            }
          } else {
            setEditValue("");
          }
        } else if (limitationValue.unlimited) {
          setEditValue("unlimited");
          setEditUnlimited(true);
        } else {
          setEditValue(limitationValue.value);
          setEditUnlimited(false);
        }

        // Add this timeout to open the dropdown immediately after rendering the Select component
        setTimeout(() => {
          const selectElement = document.querySelector(
            `[data-set="${setId}"][data-limitation="${limitationId}"] .MuiSelect-select`,
          );
          if (selectElement) {
            (selectElement as HTMLElement).click();
          }
        }, 50);
      }
    }
  };

  // Get limitation by ID
  const getLimitation = (id: string) => {
    return limitations.find((lim) => lim.id === id);
  };

  // Get formatted limitation value display
  const getFormattedLimitationValue = (limitationId: string, limitationValue: LimitationValue | undefined) => {
    if (!limitationValue) return "-";

    const limitation = getLimitation(limitationId);
    if (!limitation) return "-";

    // If unlimited is set, show "Unlimited" instead of the value
    if (limitationValue.unlimited) {
      return "Unlimited";
    }

    // For boolean type, just return Yes or No
    if (limitation.type === "boolean") {
      return limitationValue.value || "Yes"; // Default to Yes if not set
    }

    // If value is empty, show appropriate default based on limitation type
    if (!limitationValue.value || limitationValue.value === "") {
      if (limitation.canBeUnlimited) {
        return "Unlimited";
      } else if (limitation.type === "number" && limitation.values.length > 0) {
        // For numbers, find the least restrictive value based on operator
        const numericValues = limitation.values.map((v) => parseFloat(v)).filter((v) => !isNaN(v));
        if (numericValues.length > 0) {
          const value =
            limitation.operator === ">" || limitation.operator === "≥"
              ? Math.min(...numericValues)
              : Math.max(...numericValues);
          return `${limitation.operator} ${value} ${limitation.unit || ""}`;
        }
      } else if (limitation.type === "list" && limitation.values.length > 0) {
        // For lists, use the least restrictive (last) value
        return limitation.values[limitation.values.length - 1];
      }
      return "-";
    }

    // If value is "none", treat as unlimited for display
    if (limitationValue.value.toLowerCase() === "none") return "Unlimited";

    // Normal value display
    if (limitation.type === "number" && limitation.operator) {
      return `${limitation.operator} ${limitationValue.value} ${limitation.unit || ""}`;
    }

    return limitationValue.value;
  };

  // Get limitation values for selection
  const getLimitationValuesForSelection = (limitationId: string) => {
    const limitation = getLimitation(limitationId);
    if (!limitation) return [];

    if (limitation.type === "boolean") {
      return [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ];
    }

    const options = limitation.values.map((value) => ({
      value,
      label:
        limitation.type === "number" && limitation.operator
          ? `${limitation.operator} ${value} ${limitation.unit || ""}`
          : value,
    }));

    // Add "Unlimited" option only when sets are enabled
    if (useSets && limitation.canBeUnlimited) {
      options.unshift({
        value: "unlimited",
        label: "Unlimited",
      });
    }

    return options;
  };

  const toggleTableEditMode = () => {
    // If turning off edit mode, clear any active edit
    if (isTableEditable) {
      setEditMode(null);
    }
    setIsTableEditable(!isTableEditable);
  };

  const selectRef = useRef<HTMLInputElement>(null);
  // Add this effect to open the dropdown when edit mode changes
  useEffect(() => {
    if (editMode && selectRef.current) {
      // Focus and open the dropdown programmatically
      selectRef.current.focus();
      selectRef.current.click();
    }
  }, [editMode]);

  return (
    <Box sx={{ padding: 4 }}>
      {/* Active Limitations Summary */}
      {/* <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Active Limitations Summary
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={useSets ? 6 : 12}>
            <Typography variant="subtitle1" gutterBottom>
              Enabled Limitations: {limitations.length === 0 ? " None" : ""}
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {limitations
                .filter((limitation) => limitation.enabled)
                .map((limitation) => (
                  <Box key={limitation.id} sx={{ display: "flex", flexDirection: "column", mb: 1 }}>
                    <Typography variant="caption" sx={{ mb: 0.5 }}>
                      {limitation.name}
                      {useSets && limitation.canBeUnlimited && " (can be unlimited)"}:
                    </Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {limitation.values.map((value, index) => (
                        <Chip
                          key={index}
                          label={
                            limitation.type === "number" && limitation.operator
                              ? `${limitation.operator} ${value} ${limitation.unit || ""}`
                              : value
                          }
                          size="small"
                          color="primary"
                          variant="outlined"
                        />
                      ))}
                    </Box>
                  </Box>
                ))}
            </Box>
          </Grid>

          {useSets && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Active Sets:
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {sets.map((set) => (
                  <Chip
                    key={set.id}
                    label={`(${set.key}) ${set.name}`}
                    color="secondary"
                    variant="outlined"
                  />
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper> */}

      {/* Tabs */}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="limitation management tabs">
          <Tab icon={<ListIcon />} label="Limitations" />
          {useSets && <Tab icon={<CategoryIcon />} label="Sets" />}
        </Tabs>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mr: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleLoadData}
            size="small"
            startIcon={<SettingsIcon />}
            sx={{
              height: 36,
              textTransform: "none",
              borderRadius: 1.5,
            }}
          >
            Load DEMO data
          </Button>
          <FormControlLabel
            control={
              <Switch
                checked={useSets}
                onChange={(e) => {
                  const newValue = e.target.checked;
                  setUseSets(newValue);

                  // When switching sets on, set all limitations' inSets to true
                  if (newValue) {
                    setLimitations(
                      limitations.map((limitation) => ({
                        ...limitation,
                        inSets: true,
                      })),
                    );

                    // Also add any limitations that weren't in sets to all sets
                    const limitationsToAdd = limitations.filter((lim) => !lim.inSets);
                    if (limitationsToAdd.length > 0 && sets.length > 0) {
                      setSets(
                        sets.map((set) => ({
                          ...set,
                          limitationValues: [
                            ...set.limitationValues,
                            ...limitationsToAdd.map((lim) => ({
                              limitationId: lim.id,
                              value: lim.type === "boolean" ? "Yes" : "",
                            })),
                          ],
                        })),
                      );
                    }
                  }
                }}
              />
            }
            label="Use Sets"
            sx={{ m: 0 }}
          />
        </Box>
      </Box>

      {/* Limitations Tab */}
      {activeTab === 0 && (
        <Grid container spacing={3}>
          {/* Limitations List */}
          <Grid item xs={12}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography variant="h5">Limitations</Typography>

                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setShowPreparedLimitations(false);
                      setShowAddLimitationForm(!showAddLimitationForm || showPreparedLimitations);
                    }}
                    startIcon={<AddIcon />}
                    size="small"
                  >
                    Custom
                  </Button>

                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setShowPreparedLimitations(true);
                      setShowAddLimitationForm(!showAddLimitationForm || !showPreparedLimitations);
                    }}
                    startIcon={<SettingsIcon />}
                    size="small"
                  >
                    Pre-prepared
                  </Button>
                </Box>
              </Box>

              {/* Add Limitation Form - only shown when a button is clicked */}
              {showAddLimitationForm && (
                <Box
                  sx={{
                    mb: 3,
                    p: 2,
                    backgroundColor: "background.paper",
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: "divider",
                  }}
                >
                  {showPreparedLimitations ? (
                    // Pre-prepared limitations content
                    <>
                      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, alignItems: "center" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedPreparedLimitations.length === preparedLimitations.length}
                              indeterminate={
                                selectedPreparedLimitations.length > 0 &&
                                selectedPreparedLimitations.length < preparedLimitations.length
                              }
                              onChange={toggleAllPreparedLimitations}
                            />
                          }
                          label="Select All"
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={selectedPreparedLimitations.length === 0}
                          onClick={handleAddPreparedLimitations}
                          startIcon={<AddIcon />}
                        >
                          Add Selected ({selectedPreparedLimitations.length})
                        </Button>
                      </Box>
                      <TableContainer component={Paper} variant="outlined">
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedPreparedLimitations.length === preparedLimitations.length}
                                  indeterminate={
                                    selectedPreparedLimitations.length > 0 &&
                                    selectedPreparedLimitations.length < preparedLimitations.length
                                  }
                                  onChange={toggleAllPreparedLimitations}
                                />
                              </TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Details</TableCell>
                              <TableCell>Values</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {preparedLimitations.map((prepared, index) => (
                              <TableRow
                                key={index}
                                selected={selectedPreparedLimitations.includes(index)}
                                onClick={() => togglePreparedLimitation(index)}
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={selectedPreparedLimitations.includes(index)}
                                    onChange={() => togglePreparedLimitation(index)}
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                </TableCell>
                                <TableCell>{prepared.name}</TableCell>
                                <TableCell>
                                  {prepared.type === "number" && "Number"}
                                  {prepared.type === "list" && "List"}
                                  {prepared.type === "boolean" && "Yes / No"}
                                </TableCell>
                                <TableCell>
                                  {prepared.type === "number" && (
                                    <>
                                      {prepared.operator} {prepared.unit}
                                    </>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    {prepared.values.map((value, idx) => (
                                      <Chip key={idx} label={value} size="small" />
                                    ))}
                                    {useSets && prepared.canBeUnlimited && (
                                      <Chip key="unlimited" label="Unlimited" size="small" color="secondary" />
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    // Custom limitation form
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={limitationType === "number" ? 4 : 8}>
                        <TextField
                          fullWidth
                          label="Limitation Name"
                          value={newLimitation}
                          onChange={(e) => setNewLimitation(e.target.value)}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Type</InputLabel>
                          <Select
                            value={limitationType}
                            label="Type"
                            onChange={(e) => setLimitationType(e.target.value as LimitationType)}
                          >
                            <MenuItem value="number">Number</MenuItem>
                            <MenuItem value="list">List</MenuItem>
                            <MenuItem value="boolean">Yes / No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      {limitationType === "number" && (
                        <>
                          <Grid item xs={12} sm={2}>
                            <FormControl fullWidth size="small">
                              <InputLabel>Operator</InputLabel>
                              <Select
                                value={limitationOperator}
                                label="Operator"
                                onChange={(e) => setLimitationOperator(e.target.value)}
                              >
                                {operators.map((op) => (
                                  <MenuItem key={op.value} value={op.value}>
                                    {op.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <FormControl fullWidth size="small">
                              <InputLabel>Unit</InputLabel>
                              <Select
                                value={limitationUnit}
                                label="Unit"
                                onChange={(e) => setLimitationUnit(e.target.value)}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {commonUnits.map((unit) => (
                                  <MenuItem key={unit.value} value={unit.value}>
                                    {unit.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} sm={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={handleAddLimitation}
                          fullWidth
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              )}

              {/* Limitations Table */}
              <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Details</TableCell>
                      <TableCell>Values</TableCell>
                      {useSets && <TableCell>In Sets</TableCell>}
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {limitations.map((limitation) => (
                      <TableRow key={limitation.id}>
                        <TableCell>{limitation.name}</TableCell>
                        <TableCell>
                          {limitation.type === "number" && "Number"}
                          {limitation.type === "list" && "List"}
                          {limitation.type === "boolean" && "Yes / No"}
                        </TableCell>
                        <TableCell>
                          {limitation.type === "number" && (
                            <>
                              {limitation.operator} {limitation.unit}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            {limitation.type === "boolean" ? (
                              <></>
                            ) : (
                              <>
                                {limitation.values.map((value, index) => (
                                  <Chip
                                    key={index}
                                    label={value}
                                    size="small"
                                    onDelete={() => deleteValueFromLimitation(limitation.id, index)}
                                  />
                                ))}
                                {useSets && limitation.canBeUnlimited && (
                                  <Chip
                                    key="unlimited"
                                    label="Unlimited"
                                    size="small"
                                    color="secondary"
                                    onDelete={() => {
                                      setLimitations(
                                        limitations.map((lim) =>
                                          lim.id === limitation.id ? { ...lim, canBeUnlimited: false } : lim,
                                        ),
                                      );
                                    }}
                                  />
                                )}
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => openLimitationValuesDialog(limitation.id)}
                                >
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </>
                            )}
                          </Box>
                        </TableCell>
                        {useSets && (
                          <TableCell>
                            <Switch
                              checked={limitation.inSets}
                              onChange={() => toggleLimitationInSets(limitation.id)}
                              size="small"
                            />
                          </TableCell>
                        )}
                        <TableCell>
                          <IconButton size="small" color="error" onClick={() => deleteLimitation(limitation.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* Sets Tab */}
      {activeTab === 1 && useSets && (
        <Grid container spacing={3}>
          {/* Sets with Limitation Values */}
          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                <Typography variant="h5">Sets with Limitation Values</Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowAddSetForm(!showAddSetForm)}
                    startIcon={<AddIcon />}
                    size="small"
                  >
                    Add New Set
                  </Button>
                  <Button
                    variant={isTableEditable ? "contained" : "outlined"}
                    color={isTableEditable ? "success" : "primary"}
                    onClick={toggleTableEditMode}
                    startIcon={isTableEditable ? <CheckIcon /> : <EditIcon />}
                    size="small"
                  >
                    {isTableEditable ? "Save Changes" : "Edit Values"}
                  </Button>
                </Box>
              </Box>

              {showAddSetForm && (
                <Box
                  sx={{
                    mb: 3,
                    p: 2,
                    backgroundColor: "background.paper",
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: "divider",
                  }}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Set Name"
                        value={newSet}
                        onChange={(e) => setNewSet(e.target.value)}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        fullWidth
                        label="Key (number or max 2 letters)"
                        value={newSetKey}
                        onChange={(e) => setNewSetKey(e.target.value)}
                        inputProps={{ maxLength: 2 }}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          handleAddSet();
                          // Optionally hide form after adding
                          // setShowAddSetForm(false);
                        }}
                        fullWidth
                      >
                        Add Set
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}

              <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Set</TableCell>
                      {limitations
                        .filter((lim) => lim.inSets)
                        .map((limitation) => (
                          <TableCell key={limitation.id} align="center">
                            {limitation.name}
                            {limitation.type === "number" && limitation.unit && <span> ({limitation.unit})</span>}
                          </TableCell>
                        ))}
                      {isTableEditable && <TableCell align="center">Actions</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sets.map((set) => (
                      <TableRow key={set.id}>
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Chip size="small" label={set.key} color="primary" />
                            <Typography variant="body1" component="span">
                              {set.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        {limitations
                          .filter((lim) => lim.inSets)
                          .map((limitation) => {
                            const limitationValue = set.limitationValues.find(
                              (val) => val.limitationId === limitation.id,
                            );

                            return (
                              <TableCell
                                key={`${set.id}-${limitation.id}`}
                                align="center"
                                sx={{
                                  position: "relative",
                                  height: "40px",
                                  padding: "4px 8px",
                                  minWidth: "120px",
                                  cursor: isTableEditable ? "pointer" : "default",
                                  "&:hover": isTableEditable ? { bgcolor: "rgba(0, 0, 0, 0.04)" } : {},
                                }}
                                data-set={set.id}
                                data-limitation={limitation.id}
                              >
                                {editMode && editMode.setId === set.id && editMode.limitationId === limitation.id ? (
                                  <Box
                                    sx={{
                                      position: "absolute", // Position absolutely
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      zIndex: 10, // Ensure it's above other content
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor: "white", // Match table background
                                    }}
                                  >
                                    <FormControl
                                      size="small"
                                      sx={{
                                        width: "calc(100% - 16px)", // Full width minus padding
                                        m: 0,
                                      }}
                                    >
                                      <Select
                                        ref={selectRef}
                                        open={!!editMode} // Force the dropdown to be open when in edit mode
                                        value={editValue === "" && editUnlimited ? "unlimited" : editValue}
                                        onChange={(e) => {
                                          setEditValue(e.target.value);
                                          // Immediately save the value when changed
                                          setSets(
                                            sets.map((set) =>
                                              set.id === editMode.setId
                                                ? {
                                                    ...set,
                                                    limitationValues: set.limitationValues.map((value) =>
                                                      value.limitationId === editMode.limitationId
                                                        ? {
                                                            ...value,
                                                            value: e.target.value === "unlimited" ? "" : e.target.value,
                                                            unlimited: e.target.value === "unlimited",
                                                          }
                                                        : value,
                                                    ),
                                                  }
                                                : set,
                                            ),
                                          );
                                          // Exit edit mode immediately
                                          setEditMode(null);
                                        }}
                                        onClose={() => {
                                          // Exit edit mode when dropdown is closed
                                          setEditMode(null);
                                        }}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                          },
                                          transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                          },
                                          PaperProps: {
                                            style: {
                                              maxHeight: 200,
                                            },
                                          },
                                        }}
                                        sx={{
                                          height: "32px",
                                          "& .MuiSelect-select": {
                                            padding: "4px 8px",
                                            fontSize: "0.875rem",
                                          },
                                        }}
                                      >
                                        {getLimitationValuesForSelection(limitation.id).map((option) => (
                                          <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    onClick={() => {
                                      if (isTableEditable) {
                                        // Only allow editing when table is editable
                                        if (limitation.type === "boolean") {
                                          const newValue = limitationValue?.value === "Yes" ? "No" : "Yes";
                                          setSets(
                                            sets.map((s) =>
                                              s.id === set.id
                                                ? {
                                                    ...s,
                                                    limitationValues: s.limitationValues.map((value) =>
                                                      value.limitationId === limitation.id
                                                        ? {
                                                            ...value,
                                                            value: newValue,
                                                          }
                                                        : value,
                                                    ),
                                                  }
                                                : s,
                                            ),
                                          );
                                        } else {
                                          startEditingLimitationValue(set.id, limitation.id);
                                        }
                                      }
                                    }}
                                  >
                                    {getFormattedLimitationValue(limitation.id, limitationValue)}
                                  </Box>
                                )}
                              </TableCell>
                            );
                          })}
                        {isTableEditable && (
                          <TableCell align="center" sx={{ padding: 0 }}>
                            <IconButton size="small" color="error" onClick={() => deleteSet(set.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* Limitation Values Dialog */}
      <Dialog open={limitationDialogOpen} onClose={closeLimitationDialog} maxWidth="sm" fullWidth>
        {selectedLimitation && (
          <>
            <DialogTitle>
              Manage Values for {limitations.find((lim) => lim.id === selectedLimitation)?.name}
            </DialogTitle>
            <DialogContent>
              {limitations.find((lim) => lim.id === selectedLimitation)?.type === "boolean" ? null : (
                <>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Current Values:
                    </Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {limitations
                        .find((lim) => lim.id === selectedLimitation)
                        ?.values.map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            size="small"
                            onDelete={() => deleteValueFromLimitation(selectedLimitation, index)}
                          />
                        ))}
                    </Box>
                  </Box>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        label="Add New Value"
                        value={newLimitationValue}
                        onChange={(e) => setNewLimitationValue(e.target.value)}
                        size="small"
                        placeholder="e.g. 25, 30"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button variant="contained" onClick={addValueToLimitation} fullWidth startIcon={<AddIcon />}>
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                  {useSets && selectedLimitation && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={limitations.find((lim) => lim.id === selectedLimitation)?.canBeUnlimited || false}
                          onChange={(e) => {
                            setLimitations(
                              limitations.map((limitation) =>
                                limitation.id === selectedLimitation
                                  ? { ...limitation, canBeUnlimited: e.target.checked }
                                  : limitation,
                              ),
                            );
                          }}
                        />
                      }
                      label="Can be unlimited"
                      sx={{ mt: 2 }}
                    />
                  )}
                </>
              )}

              {limitations.find((lim) => lim.id === selectedLimitation)?.type === "list" && (
                <>
                  <Typography variant="subtitle1" gutterBottom>
                    List Values:
                  </Typography>
                  <List>
                    {listValues.map((value, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={`${value}`} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={() => deleteListItem(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                  <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        label="Add New Item"
                        value={newListItem}
                        onChange={(e) => setNewListItem(e.target.value)}
                        size="small"
                        placeholder="e.g. High, Medium, Low"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button variant="contained" onClick={addListItem} fullWidth startIcon={<AddIcon />}>
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}

              {limitations.find((lim) => lim.id === selectedLimitation)?.type === "boolean" && (
                <>
                  <Typography variant="subtitle1" gutterBottom>
                    Boolean Values:
                  </Typography>
                  <RadioGroup
                    value={limitations.find((lim) => lim.id === selectedLimitation)?.values[0] || "Yes"}
                    onChange={(e) => {
                      if (selectedLimitation) {
                        setLimitations(
                          limitations.map((limitation) =>
                            limitation.id === selectedLimitation
                              ? { ...limitation, values: [e.target.value, e.target.value === "Yes" ? "No" : "Yes"] }
                              : limitation,
                          ),
                        );
                      }
                    }}
                  >
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={closeLimitationDialog}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default Limitations;
