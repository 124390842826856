import { useEffect, useRef, useState } from "react";

import Typography from "@mui/material/Typography";
import RemoveIcon from "@mui/icons-material/HighlightOff";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";
import { InputAdornment } from "@mui/material";

import { getCrewOperationsCourses, getCrewUsersOverview } from "../../api/crew";
import { getAircraftSpecialization } from "../../api/crew";

import CrewCourseOverviewTable from "../../components/CrewCourseOverviewTable";
import SearchField from "../../components/TableComponent/SearchField";
import { formatDate, formatLastFirstName } from "../../services/formatters";
import Button from "../../components/Button";
import { LimitationsFilter } from "../position-overview";

import "./index.scss";

import Box from "@mui/material/Box";
import {
  getBufferZoneStartDate,
  getRemainingValidityDays,
  mapUserCertificates,
  userCourseMatch,
} from "src/services/certificate";
import { COMMON, COURSE_STATUS } from "src/constants";
import SkeletonTableLoader from "src/components/SkeletonTableLoader/SkeletonTableLoader";
import { getCategories } from "src/api/categories";
import CertificateService from "../../services/certificate";
import { useTranslation } from "react-i18next";
import { isValidDate } from "src/utils/isValidDate";
import { Autocomplete } from "src/components/Form/Autocomplete";
import { createSelectableObject } from "src/components/Form/types";

const groupByCourseCategory = (courses) => {
  return Object.values(_.groupBy(courses, (course) => course.courseCategory || COMMON.UNCATIGORIZED));
};

const getCourses = (operations) => {
  return operations
    .flatMap((op) => [
      ...op.courses.map((course) => ({
        ...course,
        category_id: op.category_id,
        category_name: op.category_name,
      })),
      ...op.customCourses.map((customCourse) => ({
        ...customCourse,
        category_id: op.category_id,
        category_name: op.category_name,
      })),
    ])
    .reduce((acc, course) => {
      const existingCourse = acc.find((c) => c.id === course.id);
      if (existingCourse) {
        const nonExistingCategory = existingCourse.categories.some((cat) => cat.category_id === course.category_id);

        if (!nonExistingCategory) {
          existingCourse.categories.push({
            category_id: course.category_id,
            category_name: course.category_name,
          });
        }
      } else {
        acc.push({
          ...course,
          categories: [
            {
              category_id: course.category_id,
              category_name: course.category_name,
            },
          ],
        });
      }

      return acc;
    }, [])
    .map(({ category_id, category_name, ...rest }) => rest);
};

const mapUserCourses = (user, operations) => {
  let coursesInfos = [];

  const { courses, customCourses } = mapUserCertificates(user);

  user.assignedRoles.forEach((role) => {
    const op = operations.find(
      (op) =>
        role.operation_id === op.operation_id &&
        role.position_id === op.position_id &&
        role.category_id === op.category_id,
    );

    op.courses.map((course) => {
      const { id, ...rest } = course;
      const courseWithRenamedId = { course_id: id, ...rest };

      const match = userCourseMatch(courseWithRenamedId, { courses });

      coursesInfos.push(match);
    });

    op.customCourses.map((cc) => {
      const { id, ...rest } = cc;
      const courseWithRenamedId = { custom_course_id: id, ...rest };

      const matchCC = CertificateService.userCustomCertificateMatch(courseWithRenamedId, {
        user_id: user.user_id,
        customCourses,
      });

      coursesInfos.push(matchCC);
    });
  });

  return coursesInfos;
};

const getUserCourseStatus = (user, coursesCategory, operations, companyUsedAircraft, selectedAircraft) => {
  const filteredAircraft = selectedAircraft
    ? companyUsedAircraft.filter((a) => a === selectedAircraft.getId())
    : companyUsedAircraft;
  const courseInfos = mapUserCourses(user, operations);
  let userPositions = [];
  coursesCategory.map((courses) => {
    // Create array to hold all course-status-aircraft combinations
    const statusArray = courses.flatMap((course) => {
      return filteredAircraft.map((aircraftId) => {
        const courseStatus = courseInfos.find(
          (item) => item.course_id === course.id || item.course_id === `cc_${course.id}`,
        );

        // Check if user has this aircraft assigned
        const hasAircraft = user.aircraft.includes(aircraftId);
        const underSupervision = Math.random() < 0.2;
        const limitationProbability = Math.random();
        let limitation = "";
        if (limitationProbability < 0.05) {
          limitation = "Visibility > 10km, Wind < 10kts";
        } else if (limitationProbability < 0.1) {
          limitation = "Visibility > 3km, Wind < 25kts";
        } else if (limitationProbability < 0.15) {
          limitation = "Visibility > 1.5km, Wind < 35kts";
        }
        const purchased = Math.random() < 0.2;
        if (!hasAircraft) {
          return {
            course,
            status: "",
            validUntil: "",
            aircraftId,
            underSupervision,
            limitation,
            purchased: false,
          };
        }

        if (isValidDate(courseStatus?.valid)) {
          courseStatus.valid = formatDate(courseStatus.valid);
        }

        if (!courseStatus) {
          return {
            course,
            status: "",
            validUntil: "",
            aircraftId,
            underSupervision,
            limitation,
            purchased: purchased,
          };
        } else if (courseStatus.status === COURSE_STATUS.MISSING) {
          return {
            course,
            status: courseStatus.status,
            validUntil: "Missing",
            aircraftId,
            underSupervision,
            limitation,
            purchased: purchased,
          };
        } else if (courseStatus.status === COURSE_STATUS.EXPIRED) {
          return {
            course,
            status: courseStatus.status,
            validUntil: "Expired",
            aircraftId,
            underSupervision,
            limitation,
            purchased: true,
          };
        } else {
          return {
            course,
            status: courseStatus.status,
            validUntil: courseStatus.valid,
            buffer: getBufferZoneStartDate(courseStatus),
            days: getRemainingValidityDays(courseStatus),
            aircraftId,
            underSupervision,
            limitation,
            purchased: true,
          };
        }
      });
    });

    userPositions.push(statusArray);
  });

  return {
    id: user.user_id,
    positions: [user.name, ...userPositions].flat(),
    roles: user.assignedRoles,
    aircraft: user.aircraft,
  };
};

const CrewOverview = (params) => {
  const crewId = params.match.params.crewId;

  const [courses, setCourses] = useState([]);
  const [users, setUsers] = useState([]);
  const [noData, setNoData] = useState(false);
  const [search, setSearch] = useState("");
  const [searchCat, setSearchCat] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchOp, setSearchOp] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [courseStatus, setCourseStatus] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [aircraft, setAircraft] = useState([]);
  const [selectedAircraft, setSelectedAircraft] = useState("");
  const [purchasedFilter, setPurchasedFilter] = useState(false);
  const [statusPatternStyle, setStatusPatternStyle] = useState(false);

  const searchRef = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    if (!crewId) return;

    // All 3 properties (category-position-operation) have to be assigned
    // in order for the course to be shown in the overviews
    (async () => {
      const [users, operations, categories, aircraft] = await Promise.all([
        getCrewUsersOverview(crewId),
        getCrewOperationsCourses(crewId),
        getCategories(crewId),
        getAircraftSpecialization(crewId),
      ]);

      const courses = getCourses(operations).sort((a, b) => {
        if (a.fullname < b.fullname) return -1;
        if (a.fullname > b.fullname) return 1;
        return 0;
      });

      const coursesCategory = groupByCourseCategory(courses);
      const companyUsedAircraftIds = [...new Set(users.flatMap((user) => user.aircraft))];
      const companyUsedAircraft = aircraft.filter((aircraft) => companyUsedAircraftIds.includes(aircraft.id));
      const updatedUsers = users
        .map((user) => {
          return getUserCourseStatus(user, coursesCategory, operations, companyUsedAircraftIds, selectedAircraft);
        })
        .sort(({ positions: [name1] }, { positions: [name2] }) =>
          formatLastFirstName(name1).localeCompare(formatLastFirstName(name2)),
        );

      const statuses = [
        { id: 1, name: "Current" },
        { id: 2, name: "Buffer zone" },
        { id: 3, name: "Expired / Missing" },
        { id: 4, name: "Expired / Missing / Buffer zone" },
      ];
      const filterStatusOptions = statuses.map((status) => createSelectableObject(status.id, status.name));

      setAllCategories(categories);
      setCourseStatus(filterStatusOptions);
      setCourses(coursesCategory);
      setUsers(updatedUsers);
      setAircraft(companyUsedAircraft);
      setNoData(!courses.length);
      setLoading(false);
    })();
  }, [crewId, selectedAircraft]);

  const togglePurchasedFilter = () => {
    setPurchasedFilter(!purchasedFilter);
  };

  return (
    <div>
      {isLoading && <SkeletonTableLoader columns={10} rows={30} cellHeight={40} headerHeight={40} />}
      {noData && <Typography variant="h4">No Data Found</Typography>}
      {courses && users && (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              padding: "1rem 0.5rem",
            }}
            className="input-wrapper"
          >
            <Box display="flex" width="100%" justifyContent="flex-start">
              <LimitationsFilter defaultFilterMode="show" mode="select" />
            </Box>

            <Box display="flex" gap="8px" alignItems="start">
              <SearchField
                placeholder={t("filter_users")}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sx={{ width: "12rem" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button icon={<RemoveIcon />} onClick={() => setSearch("")} title={"Clear filter"} />
                    </InputAdornment>
                  ),
                }}
              />

              <SearchField
                placeholder={t("filter_courses")}
                value={searchOp}
                onChange={(e) => setSearchOp(e.target.value)}
                sx={{ width: "12rem" }}
                InputProps={{
                  ref: searchRef,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button icon={<RemoveIcon />} onClick={() => setSearchOp("")} title={"Clear filter"} />
                    </InputAdornment>
                  ),
                }}
              />

              <Autocomplete
                items={allCategories}
                selected={selectedCategory}
                label={t("filter_category")}
                onChange={(selected) => {
                  setSelectedCategory(selected);
                  setSearchCat(selected && selected.getId());
                }}
                sx={{ width: "12rem" }}
              />
              <Autocomplete
                items={courseStatus}
                selected={selectedStatus}
                label={"Filter status"}
                onChange={(selected) => {
                  setSelectedStatus(selected);
                  setSearchStatus(selected && selected.getId());
                }}
                sx={{ width: "12rem" }}
              />
              <Autocomplete
                items={aircraft.map((aircraft) => createSelectableObject(aircraft.id, aircraft.name || aircraft.type))}
                selected={selectedAircraft}
                label={t("Filter aircraft")}
                onChange={(selected) => {
                  setSelectedAircraft(selected);
                }}
                sx={{ width: "12rem" }}
              />
              <Button
                icon={
                  <ShoppingCartCheckoutIcon
                    sx={{
                      color: purchasedFilter ? "#a6e22e" : "inherit",
                    }}
                  />
                }
                onClick={() => setPurchasedFilter(!purchasedFilter)}
                title={purchasedFilter ? "Show all courses" : "Show not purchased courses only"}
              />
              <Button
                icon={
                  <FormatColorResetIcon
                    sx={{
                      color: statusPatternStyle ? "#a6e22e" : "inherit",
                    }}
                  />
                }
                onClick={() => setStatusPatternStyle(!statusPatternStyle)}
                title={statusPatternStyle ? "Show white ring" : "Show blue circle"}
              />
            </Box>
          </Box>

          <CrewCourseOverviewTable
            crewId={crewId}
            courses={courses}
            aircraft={selectedAircraft ? aircraft.filter((a) => a.id === selectedAircraft.getId()) : aircraft}
            users={users.filter(
              ({ positions: [name] }) => !search || name.toLowerCase().includes(search.toLowerCase()),
            )}
            onUserSelected={(user) => setSearch(search === user ? "" : user)}
            onCourseSelected={(course) => setSearchOp(searchOp === course ? "" : course)}
            onCategorySelected={(courseCategory) => {
              setSearchText(searchText === courseCategory ? "" : courseCategory);
            }}
            searchOp={searchOp}
            searchRef={searchRef}
            searchCat={searchCat}
            searchText={searchText}
            searchStatus={searchStatus}
            filterPurchased={purchasedFilter}
            statusPatternStyle={statusPatternStyle}
          />
        </Box>
      )}
    </div>
  );
};

export default CrewOverview;
